import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PowerSelect\n    @options={{this.options}}\n    @selected={{this.selectedOption}}\n    @onChange={{this.selectOption}}\n    @disabled={{eq this.settings.membersSignupAccess \"none\"}}\n    @renderInPlace={{true}}\n    @triggerClass=\"gh-setting-dropdown\"\n    @dropdownClass=\"gh-setting-dropdown-list\"\n    as |option|\n>\n    <div class=\"gh-radio-label {{dasherize option.value}}\">\n        <div class=\"description\">\n            <h4>{{option.name}}</h4>\n        </div>\n    </div>\n</PowerSelect>", {"contents":"<PowerSelect\n    @options={{this.options}}\n    @selected={{this.selectedOption}}\n    @onChange={{this.selectOption}}\n    @disabled={{eq this.settings.membersSignupAccess \"none\"}}\n    @renderInPlace={{true}}\n    @triggerClass=\"gh-setting-dropdown\"\n    @dropdownClass=\"gh-setting-dropdown-list\"\n    as |option|\n>\n    <div class=\"gh-radio-label {{dasherize option.value}}\">\n        <div class=\"description\">\n            <h4>{{option.name}}</h4>\n        </div>\n    </div>\n</PowerSelect>","moduleName":"ghost-admin/components/gh-font-selector.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-font-selector.hbs"}});
import Component from '@glimmer/component';
import {action} from '@ember/object';

export default class GhFontSelector extends Component {
    get options() {
        return [{
            name: 'Elegant serif',
            description: 'Beautiful lines with great readability',
            value: 'serif'
        }, {
            name: 'Clean sans-serif',
            description: 'A more minimal style with sharp lines',
            value: 'sans_serif'
        }];
    }

    get selectedOption() {
        return this.options.find(o => o.value === this.args.selected);
    }

    @action
    selectOption(option) {
        this.args.onChange(option.value);
    }
}
