import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex flex-column items-stretch\">\n    {{!-- <p class=\"\">Get started with one of the following options</p> --}}\n    <button class=\"gh-btn gh-btn-green\" {{on \"click\" this.addYourself}}>\n        <span>Add yourself as a member to test</span>\n    </button>\n\n    <div class=\"flex flex-column items-stretch mt5 pt5\">\n        <LinkTo @route=\"member.new\" class=\"gh-btn gh-btn-outline mb3\">\n            <span>Manually add a member</span>\n        </LinkTo>\n\n        <LinkTo @route=\"members.import\" class=\"gh-btn gh-btn-outline\">\n            <span>Import members from CSV</span>\n        </LinkTo>\n    </div>\n</div>", {"contents":"<div class=\"flex flex-column items-stretch\">\n    {{!-- <p class=\"\">Get started with one of the following options</p> --}}\n    <button class=\"gh-btn gh-btn-green\" {{on \"click\" this.addYourself}}>\n        <span>Add yourself as a member to test</span>\n    </button>\n\n    <div class=\"flex flex-column items-stretch mt5 pt5\">\n        <LinkTo @route=\"member.new\" class=\"gh-btn gh-btn-outline mb3\">\n            <span>Manually add a member</span>\n        </LinkTo>\n\n        <LinkTo @route=\"members.import\" class=\"gh-btn gh-btn-outline\">\n            <span>Import members from CSV</span>\n        </LinkTo>\n    </div>\n</div>","moduleName":"ghost-admin/components/gh-members-no-members.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-members-no-members.hbs"}});
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency-decorators';

export default class GhMembersNoMembersComponent extends Component {
    @service session;
    @service store;
    @service notifications;

    @action
    addYourself() {
        return this.addTask.perform();
    }

    @task({drop: true})
    *addTask() {
        const user = yield this.session.user;

        const member = this.store.createRecord('member', {
            email: user.get('email'),
            name: user.get('name')
        });

        try {
            yield member.save();

            if (this.args.afterCreate) {
                this.args.afterCreate();
            }

            this.notifications.showNotification('Member added',
                {
                    description: 'You\'ve successfully added yourself as a member.'
                }
            );

            return member;
        } catch (error) {
            if (error) {
                this.notifications.showAPIError(error, {key: 'member.save'});
            }
        }
    }
}
