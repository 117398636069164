import Controller from '@ember/controller';
import {alias, filter} from '@ember/object/computed';

export default Controller.extend({
    tags: alias('model'),

    filteredTags: filter('tags', function (tag) {
        return !!tag.slug;
    })
});
