import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<header class=\"modal-header\">\n    <h1>Confirm snippet deletion</h1>\n</header>\n<a class=\"close\" href=\"\" role=\"button\" title=\"Close\" {{action \"closeModal\"}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></a>\n\n<div class=\"modal-body\">\n    <p>\n        You're about to delete the \"<strong>{{this.snippet.name}}</strong>\" snippet. This is permanent, and will delete the snippet for all staff users. It will <strong>not</strong> change any posts where you’ve used this snippet in the past.\n    </p>\n</div>\n\n<div class=\"modal-footer\">\n    <button {{action \"closeModal\"}} class=\"gh-btn\"><span>Cancel</span></button>\n    <GhTaskButton\n        @buttonText=\"Delete snippet\"\n        @successText=\"Deleted\"\n        @task={{this.deleteSnippet}}\n        @taskArgs={{this.snippet}}\n        @class=\"gh-btn gh-btn-red gh-btn-icon\" />\n</div>\n", {"contents":"<header class=\"modal-header\">\n    <h1>Confirm snippet deletion</h1>\n</header>\n<a class=\"close\" href=\"\" role=\"button\" title=\"Close\" {{action \"closeModal\"}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></a>\n\n<div class=\"modal-body\">\n    <p>\n        You're about to delete the \"<strong>{{this.snippet.name}}</strong>\" snippet. This is permanent, and will delete the snippet for all staff users. It will <strong>not</strong> change any posts where you’ve used this snippet in the past.\n    </p>\n</div>\n\n<div class=\"modal-footer\">\n    <button {{action \"closeModal\"}} class=\"gh-btn\"><span>Cancel</span></button>\n    <GhTaskButton\n        @buttonText=\"Delete snippet\"\n        @successText=\"Deleted\"\n        @task={{this.deleteSnippet}}\n        @taskArgs={{this.snippet}}\n        @class=\"gh-btn gh-btn-red gh-btn-icon\" />\n</div>\n","moduleName":"ghost-admin/components/modal-delete-snippet.hbs","parseOptions":{"srcName":"ghost-admin/components/modal-delete-snippet.hbs"}});
import ModalComponent from 'ghost-admin/components/modal-base';
import {alias} from '@ember/object/computed';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency';

export default ModalComponent.extend({
    router: service(),
    notifications: service(),

    snippet: alias('model'),

    actions: {
        confirm() {
            this.deleteSnippet.perform();
        }
    },

    deleteSnippet: task(function* (snippet) {
        try {
            yield this.confirm(snippet);
        } catch (error) {
            this.notifications.showAPIError(error, {key: 'snippet.delete.failed'});
        } finally {
            this.send('closeModal');
        }
    }).drop()
});
