import Service, {inject as service} from '@ember/service';
import {get, set} from '@ember/object';
import {htmlSafe} from '@ember/template';

export default Service.extend({
    notifications: service(),

    isRequired: false,
    message: '',

    // called when notifications are fetched during app boot for notifications
    // where the `location` is not 'top' and `custom` is false
    handleUpgradeNotification(notification) {
        let message = get(notification, 'message');
        set(this, 'message', htmlSafe(message));
    },

    // called when a MaintenanceError is encountered
    maintenanceAlert() {
        this.notifications.showAlert(
            'Sorry, Ghost is currently undergoing maintenance, please wait a moment then try again.',
            {type: 'error', key: 'api-error.under-maintenance'}
        );
    }
});
