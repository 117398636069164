import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<header class=\"modal-header\">\n    <h1>Would you really like to delete all content from your blog?</h1>\n</header>\n<a class=\"close\" href=\"\" role=\"button\" title=\"Close\" {{action \"closeModal\"}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></a>\n\n<div class=\"modal-body\">\n    <p>This is permanent! No backups, no restores, no magic undo button. We warned you, k?</p>\n</div>\n\n<div class=\"modal-footer\">\n    <button {{action \"closeModal\"}} class=\"gh-btn\"><span>Cancel</span></button>\n    <GhTaskButton @buttonText=\"Delete\" @successText=\"Deleted\" @task={{this.deleteAll}} @class=\"gh-btn gh-btn-red gh-btn-icon\" />\n</div>\n", {"contents":"<header class=\"modal-header\">\n    <h1>Would you really like to delete all content from your blog?</h1>\n</header>\n<a class=\"close\" href=\"\" role=\"button\" title=\"Close\" {{action \"closeModal\"}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></a>\n\n<div class=\"modal-body\">\n    <p>This is permanent! No backups, no restores, no magic undo button. We warned you, k?</p>\n</div>\n\n<div class=\"modal-footer\">\n    <button {{action \"closeModal\"}} class=\"gh-btn\"><span>Cancel</span></button>\n    <GhTaskButton @buttonText=\"Delete\" @successText=\"Deleted\" @task={{this.deleteAll}} @class=\"gh-btn gh-btn-red gh-btn-icon\" />\n</div>\n","moduleName":"ghost-admin/components/modal-delete-all.hbs","parseOptions":{"srcName":"ghost-admin/components/modal-delete-all.hbs"}});
import ModalComponent from 'ghost-admin/components/modal-base';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency';

export default ModalComponent.extend({

    ghostPaths: service(),
    notifications: service(),
    store: service(),
    ajax: service(),

    actions: {
        confirm() {
            this.deleteAll.perform();
        }
    },

    _deleteAll() {
        let deleteUrl = this.get('ghostPaths.url').api('db');
        return this.ajax.del(deleteUrl);
    },

    _unloadData() {
        this.store.unloadAll('post');
        this.store.unloadAll('tag');
    },

    _showSuccess() {
        this.notifications.showAlert('All content deleted from database.', {type: 'success', key: 'all-content.delete.success'});
    },

    _showFailure(error) {
        this.notifications.showAPIError(error, {key: 'all-content.delete'});
    },

    deleteAll: task(function* () {
        try {
            yield this._deleteAll();
            this._unloadData();
            this._showSuccess();
        } catch (error) {
            this._showFailure(error);
        } finally {
            this.send('closeModal');
        }
    }).drop()
});
