import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"gh-launch-wizard-settings-container\">\n    <div class=\"overflow-auto flex-grow-1\">\n        <h4>All looks good?</h4>\n        <p>You are all set up to start creating content, grow an audience and make your first sale!</p>\n        <p>You can further customize your site in Settings.</p>\n    </div>\n    <div class=\"gh-launch-wizard-nav-buttons\">\n        <button type=\"button\" class=\"gh-btn gh-btn-outline gh-btn-icon-dark gh-btn-large w-30\" {{on \"click\" @backStep}}><span>{{svg-jar \"arrow-left-tail\"}}</span></button>\n        <GhTaskButton\n            @task={{this.finaliseTask}}\n            @buttonText=\"Launch your site!\"\n            @runningText=\"Launching...\"\n            @class=\"w-70 ml4 gh-btn gh-btn-black gh-btn-large gh-btn-icon gh-launch-wizard-btn\"\n            data-test-button=\"wizard-finish\"\n        />\n    </div>\n</div>", {"contents":"<div class=\"gh-launch-wizard-settings-container\">\n    <div class=\"overflow-auto flex-grow-1\">\n        <h4>All looks good?</h4>\n        <p>You are all set up to start creating content, grow an audience and make your first sale!</p>\n        <p>You can further customize your site in Settings.</p>\n    </div>\n    <div class=\"gh-launch-wizard-nav-buttons\">\n        <button type=\"button\" class=\"gh-btn gh-btn-outline gh-btn-icon-dark gh-btn-large w-30\" {{on \"click\" @backStep}}><span>{{svg-jar \"arrow-left-tail\"}}</span></button>\n        <GhTaskButton\n            @task={{this.finaliseTask}}\n            @buttonText=\"Launch your site!\"\n            @runningText=\"Launching...\"\n            @class=\"w-70 ml4 gh-btn gh-btn-black gh-btn-large gh-btn-icon gh-launch-wizard-btn\"\n            data-test-button=\"wizard-finish\"\n        />\n    </div>\n</div>","moduleName":"ghost-admin/components/gh-launch-wizard/finalise.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-launch-wizard/finalise.hbs"}});
import Component from '@glimmer/component';
import {htmlSafe} from '@ember/template';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency-decorators';

export default class GhLaunchWizardFinaliseComponent extends Component {
    @service feature;
    @service notifications;
    @service router;
    @service settings;

    willDestroy() {
        super.willDestroy?.(...arguments);
        // clear any unsaved settings changes when going back/forward/closing
        this.settings.rollbackAttributes();
    }

    async saveProduct() {
        const data = this.args.getData();
        this.product = data?.product;
        if (this.product) {
            const monthlyAmount = data.monthlyAmount * 100;
            const yearlyAmount = data.yearlyAmount * 100;
            const currency = data.currency;
            const monthlyPrice = {
                nickname: 'Monthly',
                amount: monthlyAmount,
                active: 1,
                currency: currency,
                interval: 'month',
                type: 'recurring'
            };
            const yearlyPrice = {
                nickname: 'Yearly',
                amount: yearlyAmount,
                active: 1,
                currency: currency,
                interval: 'year',
                type: 'recurring'
            };
            this.product.set('monthlyPrice', monthlyPrice);
            this.product.set('yearlyPrice', yearlyPrice);
            const savedProduct = await this.product.save();
            return savedProduct;
        }
    }

    @task
    *finaliseTask() {
        const data = this.args.getData();
        if (data?.product) {
            yield this.saveProduct();
            yield this.settings.save();
        }
        yield this.feature.set('launchComplete', true);
        this.router.transitionTo('posts');
        this.notifications.showNotification(
            'Launch complete!',
            {type: 'success', actions: htmlSafe('<a href="#/posts">Start creating content</a>')}
        );
    }
}
