import ApolloService from 'ember-apollo-client/services/apollo';
import {Promise} from 'rsvp';
import {inject as service} from '@ember/service';
import {setContext} from '@apollo/client/link/context';

export default class OverriddenApollo extends ApolloService {
    @service session;

    clientOptions() {
        const opts = super.clientOptions();
        return {...opts, ssrMode: true};
    }

    link() {
        let httpLink = super.link();

        let authLink = setContext((request, context) => {
            return this._runAuthorize(request, context);
        });
        return authLink.concat(httpLink);
    }

    _runAuthorize() {
        if (!this.session.data?.authenticated?.access_token) {
            return {};
        }
        return new Promise((success) => {
            let headers = {};
            let token = this.session.data.authenticated.access_token;
            headers.Authorization = `Bearer ${token}`;

            success({headers});
        });
    }
}
