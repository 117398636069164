import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<header class=\"modal-header\">\n    <h1>Change user role</h1>\n</header>\n<a class=\"close\" href=\"\" role=\"button\" title=\"Close\" {{on \"click\" this.close}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></a>\n\n<div class=\"modal-body\" {{did-insert this.setRoleFromModel}}>\n    <GhRoleSelection\n        @selected={{this.role}}\n        @setRole={{fn (mut this.role)}}\n    />\n</div>\n\n<div class=\"modal-footer\">\n    <button {{on \"click\" this.close}} class=\"gh-btn\"><span>Cancel</span></button>\n    <button {{on \"click\" this.confirmAction}} class=\"gh-btn gh-btn-black\"><span>Change role</span></button>\n</div>\n", {"contents":"<header class=\"modal-header\">\n    <h1>Change user role</h1>\n</header>\n<a class=\"close\" href=\"\" role=\"button\" title=\"Close\" {{on \"click\" this.close}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></a>\n\n<div class=\"modal-body\" {{did-insert this.setRoleFromModel}}>\n    <GhRoleSelection\n        @selected={{this.role}}\n        @setRole={{fn (mut this.role)}}\n    />\n</div>\n\n<div class=\"modal-footer\">\n    <button {{on \"click\" this.close}} class=\"gh-btn\"><span>Cancel</span></button>\n    <button {{on \"click\" this.confirmAction}} class=\"gh-btn gh-btn-black\"><span>Change role</span></button>\n</div>\n","moduleName":"ghost-admin/components/modal-select-user-role.hbs","parseOptions":{"srcName":"ghost-admin/components/modal-select-user-role.hbs"}});
import ModalBase from 'ghost-admin/components/modal-base';
import classic from 'ember-classic-decorator';
import {action} from '@ember/object';
import {tracked} from '@glimmer/tracking';

// TODO: update modals to work fully with Glimmer components
@classic
export default class ModalPostPreviewComponent extends ModalBase {
    @tracked role;

    // TODO: rename to confirm() when modals have full Glimmer support
    @action
    confirmAction() {
        this.confirm(this.role);
        this.close();
    }

    @action
    close(event) {
        event?.preventDefault?.();
        this.closeModal();
    }

    @action
    setRoleFromModel() {
        this.role = this.model;
    }

    actions = {
        confirm() {
            this.confirmAction(...arguments);
        },

        // needed because ModalBase uses .send() for keyboard events
        closeModal() {
            this.close();
        }
    }
}
