import ApplicationAdapter from 'ghost-admin/adapters/application';
import {get} from '@ember/object';

import createRecord from 'ghost-admin/gql/mutations/post/create';
import deleteRecord from 'ghost-admin/gql/mutations/post/delete';
import list from 'ghost-admin/gql/queries/post/list';
import publish from 'ghost-admin/gql/mutations/post/publish';
import unpublish from 'ghost-admin/gql/mutations/post/unpublish';
import updateRecord from 'ghost-admin/gql/mutations/post/update';

const statusFilter = status => entity => status.includes(entity.meta.status);
const isPublished = statusFilter(['published']);

export default class PostAdapter extends ApplicationAdapter {
    query(store, type, query) {
        const filter = this._filterFromString(query.filter);
        const variables = {
            ...query,
            author_in: filter.author,
            tags_in: filter.tag
        };

        return this.apolloManageCMS.watchQuery({query: list, variables, fetchPolicy: 'no-cache'})
            .then(({list: {error, data}, tags, authors}) => {
                if (error) {
                    throw new Error(error.message);
                }
                const publishedTags = (tags.data || []).filter(isPublished);
                const publishedAuthors = (authors.data || []).filter(isPublished);

                return {
                    filter,
                    post: data,
                    tag: publishedTags,
                    author: publishedAuthors
                };
            });
    }

    queryRecord(store, type, query) {
        return this.apolloManageCMS.watchQuery({
            query: list,
            variables: {
                id: query.id
            }, fetchPolicy: 'no-cache'
        }).then(({list: {error, data}, tags, authors}) => {
            if (error) {
                throw new Error(error.message);
            }

            const publishedTags = (tags.data || []).filter(isPublished);
            const publishedAuthors = (authors.data || []).filter(isPublished);

            return {
                post: data,
                tag: publishedTags,
                author: publishedAuthors
            };
        });
    }

    createRecord(store, type, snapshot) {
        const payload = this.preparePayload(store, type, snapshot);

        return this.apolloManageCMS.mutate({mutation: createRecord, variables: payload.data.post}, `createRecord`)
            .then(this.prepareResponse(type));
    }

    updateRecord(store, type, snapshot) {
        const payload = this.preparePayload(store, type, snapshot);
        const variables = {
            ...payload.data.post,
            id: payload.data.post.revisionId
        };
        const desiredStatus = get(payload, 'data.post.status');

        if (desiredStatus === 'published') {
            return this.apolloManageCMS.mutate({mutation: updateRecord, variables}, `updateRecord`)
                .then(this.prepareResponse(type))
                .then(({post: {revisionId}}) => this._publish(revisionId))
                .then(this.prepareResponse(type));
        }
        const changed = snapshot.changedAttributes();
        if (changed.status && changed.status.length === 2 && changed.status[0] === 'published' && changed.status[1] === 'draft') {
            return this._unpublish(variables.id)
                .then(this.prepareResponse(type))
                .then(() => this.apolloManageCMS.mutate({mutation: updateRecord, variables}, `updateRecord`))
                .then(this.prepareResponse(type));
        }
        return this.apolloManageCMS.mutate({mutation: updateRecord, variables}, `updateRecord`)
            .then(this.prepareResponse(type));
    }

    deleteRecord(store, type, snapshot) {
        const payload = this.preparePayload(store, type, snapshot);
        const variables = {
            id: get(payload, 'data.post.revisionId')
        };

        if ('draft' === get(payload, 'data.post.status')) {
            return this.apolloManageCMS.mutate({mutation: deleteRecord, variables}, `deleteRecord`).then(() => null);
        }

        return this._unpublish(variables.id).then(
            () => {
                this.apolloManageCMS.mutate({mutation: deleteRecord, variables}, `deleteRecord`);
            }
        );
    }

    _publish(id) {
        const variables = {
            id
        };
        return this.apolloManageCMS.mutate({mutation: publish, variables}, 'publish');
    }

    _unpublish(id) {
        const variables = {
            id
        };
        return this.apolloManageCMS.mutate({mutation: unpublish, variables}, 'unpublish');
    }

    preparePayload(store, type, snapshot) {
        const serializer = store.serializerFor(type.modelName);
        let payload = {};
        serializer.serializeIntoHash(payload, type, snapshot);

        const preparedData = {
            post: {
                ...payload.post,
                tags: payload.post.tags ? payload.post.tags.map(id => ({modelId: 'tag', entryId: id})) : [],
                author: payload.post.author ? {modelId: 'author', entryId: payload.post.author} : null
            }
        };
        return {data: preparedData};
    }
}
