import CookieStore from 'ember-simple-auth/session-stores/cookie';
import {inject as service} from '@ember/service';

export default CookieStore.extend({
    session: service()

    // when loading the app we want ESA to try fetching the currently logged
    // in user. This will succeed/fail depending on whether we have a valid
    // session cookie or not so we can use that as an indication of the session
    // being authenticated
/*    restore() {
        return this.session.populateUser().then(() => {
            // provide the necessary data for internal-session to mark the
            // session as authenticated
            //todo, refresh data and access token
            let data = {authenticated: {authenticator: 'authenticator:cookie'}};
            this.persist(data);
            return data;
        }).catch((e) => {
            return RSVP.reject();
        });
    }*/
});
