import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<input class=\"gh-input gh-input-file q\" data-url=\"upload\" type=\"file\" name=\"importfile\" accept=\"{{this.acceptEncoding}}\">\n<button id=\"startupload\" class=\"gh-btn gh-btn-hover-blue\" type=\"submit\" disabled={{this.uploadButtonDisabled}} {{action \"upload\"}}>\n    <span>{{this.uploadButtonText}}</span>\n</button>\n", {"contents":"<input class=\"gh-input gh-input-file q\" data-url=\"upload\" type=\"file\" name=\"importfile\" accept=\"{{this.acceptEncoding}}\">\n<button id=\"startupload\" class=\"gh-btn gh-btn-hover-blue\" type=\"submit\" disabled={{this.uploadButtonDisabled}} {{action \"upload\"}}>\n    <span>{{this.uploadButtonText}}</span>\n</button>\n","moduleName":"ghost-admin/components/gh-file-upload.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-file-upload.hbs"}});
import Component from '@ember/component';

export default Component.extend({
    _file: null,

    acceptEncoding: null,
    uploadButtonText: 'Text',
    uploadButtonDisabled: true,

    shouldResetForm: true,

    // closure actions
    onUpload() {},
    onAdd() {},

    actions: {
        upload() {
            if (!this.uploadButtonDisabled && this._file) {
                this.onUpload(this._file);
            }

            // Prevent double post by disabling the button.
            this.set('uploadButtonDisabled', true);

            // Reset form
            if (this.shouldResetForm) {
                this.element.closest('form').reset();
            }
        }
    },

    change(event) {
        this.set('uploadButtonDisabled', false);
        this.onAdd();
        this._file = event.target.files[0];
    }
});
