import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<header class=\"modal-header\" data-test-modal=\"unsaved-settings\">\n    <h1>Are you sure you want to leave this page?</h1>\n</header>\n<a class=\"close\" href=\"\" role=\"button\" title=\"Close\" {{action \"closeModal\"}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></a>\n\n<div class=\"modal-body\">\n    <p>\n        Hey there! It looks like you didn't save the changes you made.\n    </p>\n\n    <p>Save before you go!</p>\n</div>\n\n<div class=\"modal-footer\">\n    <button {{action \"closeModal\"}} class=\"gh-btn\" data-test-stay-button><span>Stay</span></button>\n    <button {{action \"confirm\"}} class=\"gh-btn gh-btn-red\" data-test-leave-button><span>Leave</span></button>\n</div>\n", {"contents":"<header class=\"modal-header\" data-test-modal=\"unsaved-settings\">\n    <h1>Are you sure you want to leave this page?</h1>\n</header>\n<a class=\"close\" href=\"\" role=\"button\" title=\"Close\" {{action \"closeModal\"}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></a>\n\n<div class=\"modal-body\">\n    <p>\n        Hey there! It looks like you didn't save the changes you made.\n    </p>\n\n    <p>Save before you go!</p>\n</div>\n\n<div class=\"modal-footer\">\n    <button {{action \"closeModal\"}} class=\"gh-btn\" data-test-stay-button><span>Stay</span></button>\n    <button {{action \"confirm\"}} class=\"gh-btn gh-btn-red\" data-test-leave-button><span>Leave</span></button>\n</div>\n","moduleName":"ghost-admin/components/modal-leave-settings.hbs","parseOptions":{"srcName":"ghost-admin/components/modal-leave-settings.hbs"}});
import ModalComponent from 'ghost-admin/components/modal-base';
import RSVP from 'rsvp';

export default ModalComponent.extend({
    actions: {
        confirm() {
            this.confirm();
            this.send('closeModal');
        }
    },

    // Allowed actions
    confirm: () => RSVP.resolve()
});
