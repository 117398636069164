import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<header class=\"modal-header\">\n    <h1>Are you sure you want to leave this page?</h1>\n</header>\n<a class=\"close\" href=\"\" role=\"button\" title=\"Close\" {{action \"closeModal\"}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></a>\n\n<div class=\"modal-body\">\n    <p>\n        Hey there! It looks like you're in the middle of writing something and\n        you haven't saved all of your content.\n    </p>\n\n    <p>Save before you go!</p>\n</div>\n\n<div class=\"modal-footer\">\n    <button {{action \"closeModal\"}} class=\"gh-btn\"><span>Stay</span></button>\n    <button {{action \"confirm\"}} class=\"gh-btn gh-btn-red\"><span>Leave</span></button>\n</div>\n", {"contents":"<header class=\"modal-header\">\n    <h1>Are you sure you want to leave this page?</h1>\n</header>\n<a class=\"close\" href=\"\" role=\"button\" title=\"Close\" {{action \"closeModal\"}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></a>\n\n<div class=\"modal-body\">\n    <p>\n        Hey there! It looks like you're in the middle of writing something and\n        you haven't saved all of your content.\n    </p>\n\n    <p>Save before you go!</p>\n</div>\n\n<div class=\"modal-footer\">\n    <button {{action \"closeModal\"}} class=\"gh-btn\"><span>Stay</span></button>\n    <button {{action \"confirm\"}} class=\"gh-btn gh-btn-red\"><span>Leave</span></button>\n</div>\n","moduleName":"ghost-admin/components/modal-leave-editor.hbs","parseOptions":{"srcName":"ghost-admin/components/modal-leave-editor.hbs"}});
import ModalComponent from 'ghost-admin/components/modal-base';
import RSVP from 'rsvp';

export default ModalComponent.extend({
    actions: {
        confirm() {
            this.confirm();
            this.send('closeModal');
        }
    },

    // Allowed actions
    confirm: () => RSVP.resolve()
});
