import ApolloService from 'ember-apollo-client/services/apollo';
import config from 'ghost-admin/config/environment';
import {Promise} from 'rsvp';
import {checkError} from 'ghost-admin/utils/adapter-utils';
import {inject as service} from '@ember/service';
import {setContext} from '@apollo/client/link/context';

export default class ApolloHeadlessCMS extends ApolloService {
    @service session;

    clientOptions() {
        const opts = super.clientOptions();
        return {...opts, ssrMode: true};
    }

    get options() {
        if (config.apolloHeadlessCMS) {
            return config.apolloHeadlessCMS;
        }
        return super.options;
    }

    link() {
        let httpLink = super.link();
        let authLink = setContext((request, context) => {
            return this._runAuthorize(request, context);
        });
        return authLink.concat(httpLink);
    }

    _runAuthorize() {
        if (!this.session.isAuthenticated) {
            return {};
        }
        return new Promise((success) => {
            let headers = {};
            let token = this.session.data.authenticated.access_token;
            headers.Authorization = `Bearer ${token}`;

            success({headers});
        });
    }

    mutate(opts, resultKey) {
        return super.mutate(opts, resultKey).then(checkError);
    }

    query(opts, resultKey) {
        return super.query(opts, resultKey).then(checkError);
    }

    queryRecord(opts, resultKey) {
        return super.queryRecord(opts, resultKey).then(checkError);
    }
}
