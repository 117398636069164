import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<header class=\"modal-header\" data-test-delete-theme-modal>\n    <h1>Are you sure you want to delete this</h1>\n</header>\n<a class=\"close\" href=\"\" role=\"button\" title=\"Close\" {{action \"closeModal\"}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></a>\n\n<div class=\"modal-body\">\n    <p>You're about to delete \"<strong>{{this.theme.label}}</strong>\". This is permanent! We warned you, k? Maybe <a href=\"#\" {{action this.download}}>download your theme before continuing</a></p>\n</div>\n\n<div class=\"modal-footer\">\n    <button {{action \"closeModal\"}} class=\"gh-btn\" data-test-cancel-button><span>Cancel</span></button>\n    <GhTaskButton @buttonText=\"Delete\" @successText=\"Deleted\" @task={{this.deleteTheme}} @class=\"gh-btn gh-btn-red gh-btn-icon\" data-test-delete-button=\"true\" />\n</div>\n", {"contents":"<header class=\"modal-header\" data-test-delete-theme-modal>\n    <h1>Are you sure you want to delete this</h1>\n</header>\n<a class=\"close\" href=\"\" role=\"button\" title=\"Close\" {{action \"closeModal\"}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></a>\n\n<div class=\"modal-body\">\n    <p>You're about to delete \"<strong>{{this.theme.label}}</strong>\". This is permanent! We warned you, k? Maybe <a href=\"#\" {{action this.download}}>download your theme before continuing</a></p>\n</div>\n\n<div class=\"modal-footer\">\n    <button {{action \"closeModal\"}} class=\"gh-btn\" data-test-cancel-button><span>Cancel</span></button>\n    <GhTaskButton @buttonText=\"Delete\" @successText=\"Deleted\" @task={{this.deleteTheme}} @class=\"gh-btn gh-btn-red gh-btn-icon\" data-test-delete-button=\"true\" />\n</div>\n","moduleName":"ghost-admin/components/modal-delete-theme.hbs","parseOptions":{"srcName":"ghost-admin/components/modal-delete-theme.hbs"}});
import ModalComponent from 'ghost-admin/components/modal-base';
import {alias} from '@ember/object/computed';
import {task} from 'ember-concurrency';

export default ModalComponent.extend({
    // Allowed actions
    confirm: () => {},

    theme: alias('model.theme'),
    download: alias('model.download'),

    actions: {
        confirm() {
            this.deleteTheme.perform();
        }
    },

    deleteTheme: task(function* () {
        try {
            yield this.confirm();
        } finally {
            this.send('closeModal');
        }
    }).drop()
});
