import Configuration from 'ember-simple-auth/configuration';
import ENV from '../config/environment';
import setupSession from 'ember-simple-auth/initializers/setup-session';
import setupSessionRestoration from 'ember-simple-auth/initializers/setup-session-restoration';
import setupSessionService from 'ember-simple-auth/initializers/setup-session-service';

export default {
    name: 'ember-simple-auth',
    initialize(registry) {
        let config = ENV['ember-simple-auth'] || {};
        config.rootURL = '/';//ghostPaths().adminRoot;
        Configuration.load(config);

        setupSession(registry);
        setupSessionService(registry);
        setupSessionRestoration(registry);
    }
};
