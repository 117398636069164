import $ from 'jquery';
import Controller, {inject as controller} from '@ember/controller';
import ValidationEngine from 'ghost-admin/mixins/validation-engine';
import {alias} from '@ember/object/computed';
import {computed} from '@ember/object';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency';

export default Controller.extend(ValidationEngine, {
    application: controller(),
    config: service(),
    notifications: service(),
    session: service(),
    settings: service(),
    cognito: service(),

    submitting: false,
    loggingIn: false,
    authProperties: null,

    flowErrors: '',
    passwordResetEmailSent: false,

    // ValidationEngine settings
    validationType: 'signin',

    init() {
        this._super(...arguments);
        this.authProperties = ['identification', 'password'];
    },

    signin: alias('model'),

    accentColor: computed.reads('config.accent_color'),

    actions: {
        authenticate() {
            return this.validateAndAuthenticate.perform();
        }
    },

    authenticate: task(function* (authStrategy, authentication) {
        try {
            return yield this.session
                .authenticate(authStrategy, authentication);
        } catch (error) {
            if (error && error.code && error.message) {
                this.set('flowErrors', (error.message));

                if (error.code === 'PasswordResetRequiredError') {
                    this.set('passwordResetEmailSent', true);
                }

                if (error.code === 'NotAuthorizedException') {
                    this.get('signin.errors').add('identification', '');
                }

                if (error.code === 'NotAuthorizedException') {
                    this.get('signin.errors').add('password', '');
                }
            } else {
                console.error(error); // eslint-disable-line no-console
                // Connection errors don't return proper status message, only req.body
                this.notifications.showAlert(
                    'There was a problem on the server.',
                    {type: 'error', key: 'session.authenticate.failed'}
                );
            }

            return Promise.reject(error);
        }
    }).drop(),

    validateAndAuthenticate: task(function* () {
        let signin = this.signin;
        let authStrategy = 'authenticator:cognito';

        this.set('flowErrors', '');
        // Manually trigger events for input fields, ensuring legacy compatibility with
        // browsers and password managers that don't send proper events on autofill
        $('#login').find('input').trigger('change');

        // This is a bit dirty, but there's no other way to ensure the properties are set as well as 'signin'
        this.hasValidated.addObjects(this.authProperties);

        try {
            yield this.validate({property: 'signin'});
            return yield this.authenticate
                .perform(authStrategy, {username: signin.get('identification'), password: signin.get('password')});
        } catch (error) {
            if (!error || !error.message) {
                const msg = 'Please fill out the form to sign in.';
                this.set('flowErrors', msg);
                return Promise.reject(msg);
            } else {
                return Promise.reject(error);
            }
        }
    }).drop(),

    forgotten: task(function* () {
        let email = this.get('signin.identification');
        let notifications = this.notifications;

        this.set('flowErrors', '');
        // This is a bit dirty, but there's no other way to ensure the properties are set as well as 'forgotPassword'
        this.hasValidated.addObject('identification');

        try {
            yield this.validate({property: 'forgotPassword'});
            yield this.cognito.forgotPassword(email);
            notifications.showAlert(
                'Please check your email for instructions.',
                {type: 'info', key: 'forgot-password.send.success'}
            );
            return true;
        } catch (error) {
            // ValidationEngine throws "undefined" for failed validation
            if (!error) {
                return this.set('flowErrors', 'We need your email address to reset your password!');
            }

            if (error && error.code && error.message) {
                let {message} = error;

                this.set('flowErrors', message);

                if (message.code === 'UserNotFoundException') {
                    this.get('signin.errors').add('identification', '');
                }
            } else {
                notifications.showAPIError(error, {defaultErrorText: 'There was a problem with the reset, please try again.', key: 'forgot-password.send'});
            }
        }
    })
});
