import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"gh-nav-search-modal\">\n    <GhSearchInput @class=\"gh-nav-search-input\" @onSelected={{action \"confirm\"}} />\n    <div class=\"gh-search-tips\">Open with Ctrl/⌘ + K</div>\n</div>", {"contents":"<div class=\"gh-nav-search-modal\">\n    <GhSearchInput @class=\"gh-nav-search-input\" @onSelected={{action \"confirm\"}} />\n    <div class=\"gh-search-tips\">Open with Ctrl/⌘ + K</div>\n</div>","moduleName":"ghost-admin/components/modal-search.hbs","parseOptions":{"srcName":"ghost-admin/components/modal-search.hbs"}});
import ModalComponent from 'ghost-admin/components/modal-base';

export default ModalComponent.extend({
    actions: {
        confirm() {
            this.closeModal();
        }
    },

    click() {
        let input = this.element.querySelector('input');
        if (input) {
            input.focus();
        }
    }
});
