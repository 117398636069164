import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield}}\n", {"contents":"{{yield}}\n","moduleName":"ghost-admin/components/gh-token-input/label-token-labs.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-token-input/label-token-labs.hbs"}});
import DraggableObject from 'ember-drag-drop/components/draggable-object';

export default DraggableObject.extend({

    attributeBindings: ['title'],
    classNames: ['label-token-labs'],

    title: 'Label'

});
