import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.showSpinner}}\n    <div class=\"gh-loading-content\">\n        <div class=\"gh-loading-spinner\"></div>\n    </div>\n{{/if}}", {"contents":"{{#if this.showSpinner}}\n    <div class=\"gh-loading-content\">\n        <div class=\"gh-loading-spinner\"></div>\n    </div>\n{{/if}}","moduleName":"ghost-admin/components/gh-loading-spinner.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-loading-spinner.hbs"}});
import Component from '@ember/component';
import {task, timeout} from 'ember-concurrency';

export default Component.extend({
    tagName: '',

    showSpinner: false,
    // ms until the loader is displayed,
    // prevents unnecessary flash of spinner
    slowLoadTimeout: 200,

    didInsertElement() {
        this._super(...arguments);
        this.startSpinnerTimeout.perform();
    },

    startSpinnerTimeout: task(function* () {
        yield timeout(this.slowLoadTimeout);
        this.set('showSpinner', true);
    })
});
