import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<GhTokenInput\n    @options={{this.availableAuthors}}\n    @selected={{this.selectedAuthor}}\n    @onChange={{action \"updateAuthors\"}}\n    @allowCreation={{false}}\n    @renderInPlace={{true}}\n    @triggerId={{this.triggerId}}\n/>", {"contents":"<GhTokenInput\n    @options={{this.availableAuthors}}\n    @selected={{this.selectedAuthor}}\n    @onChange={{action \"updateAuthors\"}}\n    @allowCreation={{false}}\n    @renderInPlace={{true}}\n    @triggerId={{this.triggerId}}\n/>","moduleName":"ghost-admin/components/gh-psm-authors-input.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-psm-authors-input.hbs"}});
import Component from '@ember/component';
import {computed} from '@ember/object';
import {inject as service} from '@ember/service';

export default Component.extend({

    store: service(),

    // public attrs
    tagName: '',
    triggerId: '',

    // internal attrs
    availableAuthors: null,

    // closure actions
    updateAuthors() {},

    availableAuthorNames: computed('availableAuthors.@each.name', function () {
        return this.availableAuthors.map(author => author.get('name').toLowerCase());
    }),

    selectedAuthor: computed('post.author', function () {
        return this.post.get('author.id') ? [this.post.author] : null;
    }),

    init() {
        this._super(...arguments);
        this.set('availableAuthors', this.store.peekAll('author'));
    },

    actions: {
        updateAuthors(newAuthors) {
            this.updateAuthors(newAuthors);
        }
    }

});
