import AuthenticatedRoute from 'ghost-admin/routes/authenticated';
import {inject as service} from '@ember/service';

export default AuthenticatedRoute.extend({
    router: service(),

    init() {
        this._super(...arguments);
        this.router.on('routeWillChange', (transition) => {
            this.showUnsavedChangesModal(transition);
        });
    },

    beforeModel() {
        this._super(...arguments);
    },

    model(params) {
        if (params.author_id) {
            return this.store.queryRecord('author', {id: params.author_id});
        } else {
            return this.store.createRecord('author');
        }
    },

    serialize(author) {
        return {author_id: author.get('id')};
    },

    setupController() {
        this._super(...arguments);
    },

    deactivate() {
        this._super(...arguments);

        // clean up newly created records and revert unsaved changes to existing
        this.controller.author.rollbackAttributes();
    },

    actions: {
        save() {
            this.controller.send('save');
        }
    },

    showUnsavedChangesModal(transition) {
        if (transition.from && transition.from.name === this.routeName && transition.targetName) {
            let {controller} = this;

            // author.changedAttributes is always true for new authors but number of changed attrs is reliable
            let isChanged = Object.keys(controller.author.changedAttributes()).length > 0;

            if (!controller.author.isDeleted && isChanged) {
                transition.abort();
                controller.send('toggleUnsavedChangesModal', transition);
            }
        }
    }
});
