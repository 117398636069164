import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<!--<LinkTo @route=\"dashboard\" data-test-mobile-nav=\"dashboard\">{{svg-jar \"house\"}}Dashboard</LinkTo>-->\n{{#if (eq this.router.currentRouteName \"posts\")}}\n    <LinkTo @route=\"posts\" @query={{hash type=null}} @classNames=\"active\" data-test-mobile-nav=\"posts\">{{svg-jar \"posts\"}}Posts</LinkTo>\n{{else}}\n    <LinkTo @route=\"posts\">{{svg-jar \"content\" data-test-mobile-nav=\"posts\"}}Posts</LinkTo>\n{{/if}}\n<LinkTo @route=\"tags\" @classNames=\"gh-nav-main-tags\" data-test-mobile-nav=\"tags\">{{svg-jar \"tag\"}}Tags</LinkTo>\n<LinkTo @route=\"authors\" @classNames=\"gh-nav-main-users\" data-test-mobile-nav=\"staff\">{{svg-jar \"staff\"}}Authors</LinkTo>\n{{yield}}\n", {"contents":"<!--<LinkTo @route=\"dashboard\" data-test-mobile-nav=\"dashboard\">{{svg-jar \"house\"}}Dashboard</LinkTo>-->\n{{#if (eq this.router.currentRouteName \"posts\")}}\n    <LinkTo @route=\"posts\" @query={{hash type=null}} @classNames=\"active\" data-test-mobile-nav=\"posts\">{{svg-jar \"posts\"}}Posts</LinkTo>\n{{else}}\n    <LinkTo @route=\"posts\">{{svg-jar \"content\" data-test-mobile-nav=\"posts\"}}Posts</LinkTo>\n{{/if}}\n<LinkTo @route=\"tags\" @classNames=\"gh-nav-main-tags\" data-test-mobile-nav=\"tags\">{{svg-jar \"tag\"}}Tags</LinkTo>\n<LinkTo @route=\"authors\" @classNames=\"gh-nav-main-users\" data-test-mobile-nav=\"staff\">{{svg-jar \"staff\"}}Authors</LinkTo>\n{{yield}}\n","moduleName":"ghost-admin/components/gh-mobile-nav-bar.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-mobile-nav-bar.hbs"}});
import Component from '@ember/component';
import {inject as service} from '@ember/service';

export default Component.extend({
    ui: service(),

    tagName: 'nav',
    classNames: ['gh-mobile-nav-bar']
});
