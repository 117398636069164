import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<header class=\"modal-header\">\n    <h1>Are you sure you want to un-suspend this user?</h1>\n</header>\n<a class=\"close\" href=\"\" role=\"button\" title=\"Close\" {{action \"closeModal\"}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></a>\n\n<div class=\"modal-body\">\n    <strong>WARNING:</strong> This user will be able to log in again and will have the same permissions they had previously.\n</div>\n\n<div class=\"modal-footer\">\n    <button {{action \"closeModal\"}} class=\"gh-btn\"><span>Cancel</span></button>\n    <GhTaskButton @buttonText=\"Un-suspend\" @successText=\"Suspended\" @task={{this.unsuspendUser}} @class=\"gh-btn gh-btn-red gh-btn-icon\" data-test-modal-confirm=\"true\" />\n</div>\n", {"contents":"<header class=\"modal-header\">\n    <h1>Are you sure you want to un-suspend this user?</h1>\n</header>\n<a class=\"close\" href=\"\" role=\"button\" title=\"Close\" {{action \"closeModal\"}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></a>\n\n<div class=\"modal-body\">\n    <strong>WARNING:</strong> This user will be able to log in again and will have the same permissions they had previously.\n</div>\n\n<div class=\"modal-footer\">\n    <button {{action \"closeModal\"}} class=\"gh-btn\"><span>Cancel</span></button>\n    <GhTaskButton @buttonText=\"Un-suspend\" @successText=\"Suspended\" @task={{this.unsuspendUser}} @class=\"gh-btn gh-btn-red gh-btn-icon\" data-test-modal-confirm=\"true\" />\n</div>\n","moduleName":"ghost-admin/components/modal-unsuspend-user.hbs","parseOptions":{"srcName":"ghost-admin/components/modal-unsuspend-user.hbs"}});
import ModalComponent from 'ghost-admin/components/modal-base';
import {alias} from '@ember/object/computed';
import {task} from 'ember-concurrency';

export default ModalComponent.extend({
    // Allowed actions
    confirm: () => {},

    user: alias('model'),

    actions: {
        confirm() {
            return this.unsuspendUser.perform();
        }
    },

    unsuspendUser: task(function* () {
        try {
            yield this.confirm();
        } finally {
            this.send('closeModal');
        }
    }).drop()
});
