/* eslint-disable camelcase */
import AuthenticatedRoute from 'ghost-admin/routes/authenticated';
import {inject as service} from '@ember/service';

export default AuthenticatedRoute.extend({
    router: service(),

    init() {
        this._super(...arguments);
        this.router.on('routeWillChange', (transition) => {
            this.showUnsavedChangesModal(transition);
        });
    },

    beforeModel() {
        this._super(...arguments);
    },

    model(params) {
        if (params.tag_id) {
            return this.store.queryRecord('tag', {id: params.tag_id});
        } else {
            return this.store.createRecord('tag');
        }
    },

    serialize(tag) {
        return {tag_id: tag.get('id')};
    },

    setupController() {
        this._super(...arguments);
    },

    deactivate() {
        this._super(...arguments);

        // clean up newly created records and revert unsaved changes to existing
        this.controller.tag.rollbackAttributes();
    },

    actions: {
        save() {
            this.controller.send('save');
        }
    },

    showUnsavedChangesModal(transition) {
        if (transition.from && transition.from.name === this.routeName && transition.targetName) {
            let {controller} = this;

            // tag.changedAttributes is always true for new tags but number of changed attrs is reliable
            let isChanged = Object.keys(controller.tag.changedAttributes()).length > 0;

            if (!controller.tag.isDeleted && isChanged) {
                transition.abort();
                controller.send('toggleUnsavedChangesModal', transition);
            }
        }
    }
});
