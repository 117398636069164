import Ember from 'ember';
import RSVP from 'rsvp';
import Service, {inject as service} from '@ember/service';
import ValidationEngine from 'ghost-admin/mixins/validation-engine';
import {get} from '@ember/object';

// ember-cli-shims doesn't export _ProxyMixin
const {_ProxyMixin} = Ember;

export default Service.extend(_ProxyMixin, ValidationEngine, {
    store: service(),

    // will be set to the single Settings model, it's a reference so any later
    // changes to the settings object in the store will be reflected
    content: null,

    validationType: 'setting',

    // this is an odd case where we only want to react to changes that we get
    // back from the API rather than local updates
    settledIcon: '',

    _loadSettings() {
        return RSVP.resolve({});
    },

    fetch() {
        if (!this.content) {
            return this.reload();
        } else {
            return RSVP.resolve(this);
        }
    },

    reload() {
        return this._loadSettings().then((settings) => {
            this.set('content', settings);
            this.set('settledIcon', get(settings, 'icon'));
            return this;
        });
    },

    async save() {
        let settings = this.content;

        if (!settings) {
            return false;
        }

        await settings.save();
        this.set('settledIcon', settings.icon);
        return settings;
    },

    rollbackAttributes() {
        return this.content?.rollbackAttributes();
    },

    changedAttributes() {
        return this.content?.changedAttributes();
    }
});
