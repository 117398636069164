import ApplicationAdapter from 'ghost-admin/adapters/application';
import {get} from '@ember/object';

import createTag from 'ghost-admin/gql/mutations/tag/create';
import deleteTag from 'ghost-admin/gql/mutations/tag/delete';
import listTags from 'ghost-admin/gql/queries/tag/list';
import publishTag from 'ghost-admin/gql/mutations/tag/publish';
import unpublishTag from 'ghost-admin/gql/mutations/tag/unpublish';
import updateTag from 'ghost-admin/gql/mutations/tag/update';

export default class TagAdapter extends ApplicationAdapter {
    query(store, type, query) {
        return this.apolloManageCMS.watchQuery({query: listTags, fetchPolicy: 'no-cache'}, 'listTags')
            .then(({data}) => {
                if (query?.include === 'count.posts') {
                    const tags = data.map((tag) => {
                        return {
                            ...tag,
                            count: {
                                posts: data.posts?.length || 0
                            }
                        };
                    });
                    return {
                        tag: tags
                    };
                }
                return {tag: data};
            });
    }

    queryRecord(store, type, query) {
        return this.apolloManageCMS.watchQuery({
            query: listTags,
            variables: {
                id: query.id
            },
            fetchPolicy: 'no-cache'
        }, 'listTags').then(({data}) => {
            return {tag: data.length > 0 ? data[0] : {}};
        });
    }

    createRecord(store, type, snapshot) {
        const payload = this.preparePayload(store, type, snapshot);

        return this.apolloManageCMS.mutate({mutation: createTag, variables: payload.data.tag}, 'createTag')
            .then(this.prepareResponse(type))
            .then(({tag: {revisionId}}) => this.publishTag(revisionId))
            .then(this.prepareResponse(type));
    }

    updateRecord(store, type, snapshot) {
        const payload = this.preparePayload(store, type, snapshot);

        return this.apolloManageCMS.mutate({mutation: updateTag, variables: payload.data.tag}, 'updateTag')
            .then(this.prepareResponse(type))
            .then(({tag: {revisionId}}) => this.publishTag(revisionId))
            .then(this.prepareResponse(type));
    }

    deleteRecord(store, type, snapshot) {
        const payload = this.preparePayload(store, type, snapshot);
        const variables = {
            id: get(payload, 'data.tag.revisionId')
        };

        return this.unpublishTag(variables.id).then(
            () => {
                this.apolloManageCMS.mutate({mutation: deleteTag, variables}, 'deleteTag');
            }
        );
    }

    publishTag(id) {
        const variables = {
            id
        };
        return this.apolloManageCMS.mutate({mutation: publishTag, variables}, 'publishTag');
    }

    unpublishTag(id) {
        const variables = {
            id
        };
        return this.apolloManageCMS.mutate({mutation: unpublishTag, variables}, 'unpublishTag');
    }
}
