import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"{{this.visibilityClass}}\">\n    <div class=\"gh-billing-container\">\n        <GhBillingIframe></GhBillingIframe>\n    </div>\n</div>", {"contents":"<div class=\"{{this.visibilityClass}}\">\n    <div class=\"gh-billing-container\">\n        <GhBillingIframe></GhBillingIframe>\n    </div>\n</div>","moduleName":"ghost-admin/components/gh-billing-modal.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-billing-modal.hbs"}});
import Component from '@ember/component';
import {computed} from '@ember/object';
import {inject as service} from '@ember/service';

export default Component.extend({
    billing: service(),

    visibilityClass: computed('billingWindowOpen', function () {
        return this.billingWindowOpen ? 'gh-billing' : 'gh-billing closed';
    })
});
