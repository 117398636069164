import Controller from '@ember/controller';
import EmberObject from '@ember/object';
import boundOneWay from 'ghost-admin/utils/bound-one-way';
import {alias} from '@ember/object/computed';
import {computed, defineProperty} from '@ember/object';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency';

const SCRATCH_PROPS = ['firstName', 'lastName'];

export default Controller.extend({
    notifications: service(),
    router: service(),

    showDeleteAuthorModal: false,

    author: alias('model'),

    scratchAuthor: computed('author', function () {
        let scratchAuthor = EmberObject.create({author: this.author});
        SCRATCH_PROPS.forEach(prop => defineProperty(scratchAuthor, prop, boundOneWay(`author.${prop}`)));
        return scratchAuthor;
    }),

    actions: {
        setProperty(propKey, value) {
            this._saveAuthorProperty(propKey, value);
        },

        openDeleteAuthorModal() {
            this.set('showDeleteAuthorModal', true);
        },

        closeDeleteAuthorModal() {
            this.set('showDeleteAuthorModal', false);
        },

        deleteAuthor() {
            return this.author.destroyRecord().then(() => {
                this.set('showDeleteAuthorModal', false);
                return this.transitionToRoute('authors');
            }, (error) => {
                return this.notifications.showAPIError(error, {key: 'author.delete'});
            });
        },

        save() {
            return this.save.perform();
        },

        toggleUnsavedChangesModal(transition) {
            let leaveTransition = this.leaveScreenTransition;

            if (!transition && this.showUnsavedChangesModal) {
                this.set('leaveScreenTransition', null);
                this.set('showUnsavedChangesModal', false);
                return;
            }

            if (!leaveTransition || transition.targetName === leaveTransition.targetName) {
                this.set('leaveScreenTransition', transition);

                // if a save is running, wait for it to finish then transition
                if (this.save.isRunning) {
                    return this.save.last.then(() => {
                        transition.retry();
                    });
                }

                // we genuinely have unsaved data, show the modal
                this.set('showUnsavedChangesModal', true);
            }
        },

        leaveScreen() {
            this.author.rollbackAttributes();
            return this.leaveScreenTransition.retry();
        }
    },

    save: task(function* () {
        let {author, scratchAuthor} = this;

        // if Cmd+S is pressed before the field loses focus make sure we're
        // saving the intended property values
        let scratchProps = scratchAuthor.getProperties(SCRATCH_PROPS);
        author.setProperties(scratchProps);

        try {
            if (author.get('errors').length !== 0) {
                return;
            }
            yield author.save();

            // replace 'new' route with 'author' route
            this.replaceRoute('author', author);

            return author;
        } catch (error) {
            if (error) {
                this.notifications.showAPIError(error, {key: 'author.save'});
            }
        }
    }).drop(),

    fetchAuthor: task(function* (id) {
        this.set('isLoading', true);
        yield this.store.queryRecord('author', {id}).then((author) => {
            this.set('author', author);
            this.set('isLoading', false);
            return author;
        });
    }),

    _saveAuthorProperty(propKey, newValue) {
        let author = this.author;
        let currentValue = author.get(propKey);

        if (newValue) {
            newValue = newValue.trim();
        }

        // avoid modifying empty values and triggering inadvertant unsaved changes modals
        if (newValue !== false && !newValue && !currentValue) {
            return;
        }

        // Quit if there was no change
        if (newValue === currentValue) {
            return;
        }

        author.set(propKey, newValue);

        // TODO: This is required until .validate/.save mark fields as validated
        author.get('hasValidated').addObject(propKey);
    }
});
