import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{this.memberCount}}", {"contents":"{{this.memberCount}}","moduleName":"ghost-admin/components/gh-members-filter-count.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-members-filter-count.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency-decorators';
import {tracked} from '@glimmer/tracking';

export default class GhMembersFilterCountComponent extends Component {
    @service membersCountCache;

    @tracked memberCount;

    constructor() {
        super(...arguments);
        this.getMembersCountTask.perform();
    }

    @task
    *getMembersCountTask() {
        this.memberCount = yield this.membersCountCache.countString(
            this.args.filter,
            {knownCount: this.args.knownCount}
        );
    }
}
