import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span class=\"gh-select\">\n    <OneWaySelect @value={{this.selectedVisibility}}\n        @options={{this.availableVisibilities}}\n        @optionValuePath=\"name\"\n        @optionLabelPath=\"label\"\n        @optionTargetPath=\"name\"\n        @update={{action \"updateVisibility\"}}\n    />\n    {{svg-jar \"arrow-down-small\"}}\n</span>", {"contents":"<span class=\"gh-select\">\n    <OneWaySelect @value={{this.selectedVisibility}}\n        @options={{this.availableVisibilities}}\n        @optionValuePath=\"name\"\n        @optionLabelPath=\"label\"\n        @optionTargetPath=\"name\"\n        @update={{action \"updateVisibility\"}}\n    />\n    {{svg-jar \"arrow-down-small\"}}\n</span>","moduleName":"ghost-admin/components/gh-psm-visibility-input.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-psm-visibility-input.hbs"}});
import Component from '@ember/component';
import {computed} from '@ember/object';
import {inject as service} from '@ember/service';

const VISIBILITIES = [
    {label: 'Public', name: 'public'},
    {label: 'Members only', name: 'members'},
    {label: 'Paid-members only', name: 'paid'}
];

export default Component.extend({

    settings: service(),
    feature: service(),

    // public attrs
    post: null,

    selectedVisibility: computed('post.visibility', function () {
        return this.get('post.visibility') || this.settings.get('defaultContentVisibility');
    }),

    init() {
        this._super(...arguments);
        this.availableVisibilities = [...VISIBILITIES];
        if (this.feature.get('multipleProducts')) {
            this.availableVisibilities.push(
                {label: 'Specific tier(s)', name: 'filter'}
            );
        }
    },

    actions: {
        updateVisibility(newVisibility) {
            this.post.set('visibility', newVisibility);
            if (newVisibility !== 'filter') {
                this.post.set('visibilityFilter', null);
            }
        }
    }
});
