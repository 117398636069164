import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<GhTokenInput\n    @extra={{hash\n        tokenComponent=\"gh-token-input/tag-token\"\n    }}\n    @onChange={{action \"updateTags\"}}\n    @options={{this.availableTags}}\n    @allowCreation={{false}}\n    @renderInPlace={{true}}\n    @selected={{this.selectedTags}}\n    @triggerId={{this.triggerId}}\n/>\n", {"contents":"<GhTokenInput\n    @extra={{hash\n        tokenComponent=\"gh-token-input/tag-token\"\n    }}\n    @onChange={{action \"updateTags\"}}\n    @options={{this.availableTags}}\n    @allowCreation={{false}}\n    @renderInPlace={{true}}\n    @selected={{this.selectedTags}}\n    @triggerId={{this.triggerId}}\n/>\n","moduleName":"ghost-admin/components/gh-psm-tags-input.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-psm-tags-input.hbs"}});
import Component from '@ember/component';
import {computed} from '@ember/object';
import {reads} from '@ember/object/computed';

import {inject as service} from '@ember/service';
import {sort} from '@ember/object/computed';

export default Component.extend({

    store: service(),

    // public attrs
    post: null,

    // internal attrs
    _availableTags: null,

    selectedTags: reads('post.tags'),

    availableTags: sort('_availableTags.[]', function (tagA, tagB) {
        // ignorePunctuation means the # in internal tag names is ignored
        return tagA.name.localeCompare(tagB.name, undefined, {ignorePunctuation: true});
    }),

    availableTagNames: computed('availableTags.@each.name', function () {
        return this.availableTags.map(tag => tag.name.toLowerCase());
    }),

    init() {
        this._super(...arguments);
        this.set('_availableTags', this.store.peekAll('tag'));
    },

    actions: {
        matchTags(tagName, term) {
            return tagName.toLowerCase() === term.trim().toLowerCase();
        },

        hideCreateOptionOnMatchingTag(term) {
            return !this.availableTagNames.includes(term.toLowerCase());
        },

        updateTags(newTags) {
            return this.set('post.tags', newTags);
        }
    },

    _findTagByName(name) {
        let withMatchingName = function (tag) {
            return tag.name.toLowerCase() === name.toLowerCase();
        };
        return this.availableTags.find(withMatchingName);
    }
});
