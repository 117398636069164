import BaseValidator from './base';
import validator from 'validator';
import {isBlank} from '@ember/utils';

export default BaseValidator.create({
    properties: ['firstName', 'lastName'],

    firstName(model) {
        let firstName = model.get('firstName');

        if (isBlank(firstName)) {
            model.get('errors').add('firstName', 'You must specify a first name for the author.');
            this.invalidate();
        } else if (firstName.match(/^,/)) {
            model.get('errors').add('firstName', 'names can\'t start with commas.');
            this.invalidate();
        } else if (!validator.isLength(firstName, 0, 191)) {
            model.get('errors').add('firstName', 'names cannot be longer than 191 characters.');
            this.invalidate();
        }
    },

    lastName(model) {
        let lastName = model.get('lastName');

        if (isBlank(lastName)) {
            model.get('errors').add('lastName', 'You must specify a last name for the author.');
            this.invalidate();
        } else if (lastName.match(/^,/)) {
            model.get('errors').add('lastName', 'names can\'t start with commas.');
            this.invalidate();
        } else if (!validator.isLength(lastName, 0, 191)) {
            model.get('errors').add('lastName', 'names cannot be longer than 191 characters.');
            this.invalidate();
        }
    }
});
