import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<GhNavMenu::Main @icon={{this.settings.settledIcon}} />", {"contents":"<GhNavMenu::Main @icon={{this.settings.settledIcon}} />","moduleName":"ghost-admin/components/gh-nav-menu.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-nav-menu.hbs"}});
import Component from '@ember/component';
import {match} from '@ember/object/computed';
import {inject as service} from '@ember/service';

export default Component.extend({
    settings: service(),
    router: service(),

    tagName: 'nav',
    classNames: ['gh-nav'],

    isSettingsRoute: match('router.currentRouteName', /^settings/)
});
