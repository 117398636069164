import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span class=\"gh-select\">\n    <OneWaySelect\n        @value={{this.value}}\n        @disabled={{this.disabled}}\n        @options={{this.availablePeriods}}\n        @optionValuePath=\"period\"\n        @optionLabelPath=\"label\"\n        @optionTargetPath=\"period\"\n        @includeBlank={{false}}\n        @promptIsSelectable={{false}}\n        @prompt=\"Select a billing period\"\n        @update={{action \"updatePeriod\"}}\n    />\n    {{svg-jar \"arrow-down-small\"}}\n</span>", {"contents":"<span class=\"gh-select\">\n    <OneWaySelect\n        @value={{this.value}}\n        @disabled={{this.disabled}}\n        @options={{this.availablePeriods}}\n        @optionValuePath=\"period\"\n        @optionLabelPath=\"label\"\n        @optionTargetPath=\"period\"\n        @includeBlank={{false}}\n        @promptIsSelectable={{false}}\n        @prompt=\"Select a billing period\"\n        @update={{action \"updatePeriod\"}}\n    />\n    {{svg-jar \"arrow-down-small\"}}\n</span>","moduleName":"ghost-admin/components/gh-products-price-billingperiod.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-products-price-billingperiod.hbs"}});
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {inject as service} from '@ember/service';

const PERIODS = [
    {label: 'Monthly', period: 'month'},
    {label: 'Yearly', period: 'year'}
];

export default class GhProductsPriceBillingPeriodComponent extends Component {
    @service feature;
    @service session;
    @service settings;

    constructor() {
        super(...arguments);
        this.availablePeriods = PERIODS;
    }

    get value() {
        const {value} = this.args;
        return value;
    }
    get disabled() {
        const {disabled} = this.args;
        return disabled || false;
    }

    @action
    updatePeriod(newPeriod) {
        if (this.args.updatePeriod) {
            this.args.updatePeriod(this.args.value, newPeriod);
        }
    }
}
