import Route from '@ember/routing/route';
import {inject as service} from '@ember/service';

export default class UnauthenticatedRoute extends Route {
    @service session;

    beforeModel() {
        const result = {setup: [{status: true}]}; //hardcoded for Parsel CMS
        const [setup] = result.setup;

        if (setup.status !== true) {
            this.transitionTo('setup');
        } else {
            return this.session.prohibitAuthentication('home');
        }
    }

    buildRouteInfoMetadata() {
        return {
            bodyClasses: ['unauthenticated-route']
        };
    }
}
