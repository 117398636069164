export default [{
    clientExtensions: {},
    database: 'mysql',
    enableDeveloperExperiments: false,
    environment: 'development',
    labs: {},
    mail: 'SMTP',
    version: '2.15.0',
    useGravatar: 'true'
}];
