import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<header class=\"modal-header\">\n    <h1>Are you sure?</h1>\n</header>\n<a class=\"close\" href=\"\" role=\"button\" title=\"Close\" {{action \"closeModal\"}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></a>\n<div class=\"modal-body\">\n    <p>\n        Deleting this integration will remove all webhooks and api keys associated with it.\n    </p>\n</div>\n<div class=\"modal-footer\">\n    <button {{action \"closeModal\"}} class=\"gh-btn\"><span>Cancel</span></button>\n    <GhTaskButton\n        @buttonText=\"Delete Integration\"\n        @successText=\"Deleted\"\n        @task={{this.deleteIntegration}}\n        @class=\"gh-btn gh-btn-red gh-btn-icon\" />\n</div>\n", {"contents":"<header class=\"modal-header\">\n    <h1>Are you sure?</h1>\n</header>\n<a class=\"close\" href=\"\" role=\"button\" title=\"Close\" {{action \"closeModal\"}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></a>\n<div class=\"modal-body\">\n    <p>\n        Deleting this integration will remove all webhooks and api keys associated with it.\n    </p>\n</div>\n<div class=\"modal-footer\">\n    <button {{action \"closeModal\"}} class=\"gh-btn\"><span>Cancel</span></button>\n    <GhTaskButton\n        @buttonText=\"Delete Integration\"\n        @successText=\"Deleted\"\n        @task={{this.deleteIntegration}}\n        @class=\"gh-btn gh-btn-red gh-btn-icon\" />\n</div>\n","moduleName":"ghost-admin/components/modal-delete-integration.hbs","parseOptions":{"srcName":"ghost-admin/components/modal-delete-integration.hbs"}});
import ModalComponent from 'ghost-admin/components/modal-base';
import {alias} from '@ember/object/computed';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency';

export default ModalComponent.extend({
    router: service(),
    notifications: service(),
    integration: alias('model'),
    actions: {
        confirm() {
            this.deleteIntegration.perform();
        }
    },
    deleteIntegration: task(function* () {
        try {
            yield this.confirm();
            this.router.transitionTo('integrations');
        } catch (error) {
            this.notifications.showAPIError(error, {key: 'integration.delete.failed'});
        } finally {
            this.send('closeModal');
        }
    }).drop()
});
