import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<LiquidWormhole @class=\"fullscreen-modal-container\">\n    <div class=\"fullscreen-modal-background\" {{action \"clickOverlay\"}}></div>\n    <div class={{this.modalClasses}}>\n        {{#if (has-block)}}\n          {{yield}}\n        {{else}}\n            {{#let (component this.modalPath) as |ModalComponent|}}\n                <ModalComponent\n                    @model={{this.model}}\n                    @confirm={{action \"confirm\"}}\n                    @closeModal={{action \"close\"}}\n                    @modifier={{this.modifier}}\n                    @updateModifier={{action (mut this.modifier)}}\n                />\n            {{/let}}\n        {{/if}}\n    </div>\n</LiquidWormhole>\n", {"contents":"<LiquidWormhole @class=\"fullscreen-modal-container\">\n    <div class=\"fullscreen-modal-background\" {{action \"clickOverlay\"}}></div>\n    <div class={{this.modalClasses}}>\n        {{#if (has-block)}}\n          {{yield}}\n        {{else}}\n            {{#let (component this.modalPath) as |ModalComponent|}}\n                <ModalComponent\n                    @model={{this.model}}\n                    @confirm={{action \"confirm\"}}\n                    @closeModal={{action \"close\"}}\n                    @modifier={{this.modifier}}\n                    @updateModifier={{action (mut this.modifier)}}\n                />\n            {{/let}}\n        {{/if}}\n    </div>\n</LiquidWormhole>\n","moduleName":"ghost-admin/components/gh-fullscreen-modal.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-fullscreen-modal.hbs"}});
import Component from '@ember/component';
import RSVP from 'rsvp';
import {computed} from '@ember/object';
import {A as emberA} from '@ember/array';
import {isBlank} from '@ember/utils';
import {run} from '@ember/runloop';
import {inject as service} from '@ember/service';

const FullScreenModalComponent = Component.extend({
    dropdown: service(),

    model: null,
    modifier: null,

    modalPath: computed('modal', function () {
        return `modal-${this.modal || 'unknown'}`;
    }),

    modalClasses: computed('modifier', function () {
        let modalClass = 'fullscreen-modal';
        let modifiers = (this.modifier || '').split(' ');
        let modalClasses = emberA([modalClass]);

        modifiers.forEach((modifier) => {
            if (!isBlank(modifier)) {
                let className = `${modalClass}-${modifier}`;
                modalClasses.push(className);
            }
        });

        return modalClasses.join(' ');
    }),

    didInsertElement() {
        this._super(...arguments);
        run.schedule('afterRender', this, function () {
            this.dropdown.closeDropdowns();
        });
    },

    actions: {
        close() {
            return this.close(...arguments);
        },

        confirm() {
            return this.confirm(...arguments);
        },

        clickOverlay() {
            this.send('close');
        }
    },

    // Allowed actions
    close: () => RSVP.resolve(),
    confirm: () => RSVP.resolve()
});

export default FullScreenModalComponent;
