import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<header class=\"modal-header\">\n    <h1>Regenerate your Staff Access Token</h1>\n</header>\n<a class=\"close\" href=\"\" role=\"button\" title=\"Close\" {{action \"closeModal\"}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></a>\n\n<div class=\"modal-body\">\n    <p>\n        You can regenerate your Staff Access Token any time, but any scripts or applications using it will need to be updated.\n    </p>\n    {{#if this.errorMessage}}\n        <p class='red'> {{this.errorMessage}}</p>\n    {{/if}}\n</div>\n\n<div class=\"modal-footer\">\n    <button {{action \"closeModal\"}} class=\"gh-btn\" autofocus><span>Cancel</span></button>\n    <button class=\"gh-btn gh-btn-icon gh-btn-red\" {{action \"confirm\"}}>\n        <span>Regenerate your Staff Access Token</span>\n    </button>\n</div>\n", {"contents":"<header class=\"modal-header\">\n    <h1>Regenerate your Staff Access Token</h1>\n</header>\n<a class=\"close\" href=\"\" role=\"button\" title=\"Close\" {{action \"closeModal\"}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></a>\n\n<div class=\"modal-body\">\n    <p>\n        You can regenerate your Staff Access Token any time, but any scripts or applications using it will need to be updated.\n    </p>\n    {{#if this.errorMessage}}\n        <p class='red'> {{this.errorMessage}}</p>\n    {{/if}}\n</div>\n\n<div class=\"modal-footer\">\n    <button {{action \"closeModal\"}} class=\"gh-btn\" autofocus><span>Cancel</span></button>\n    <button class=\"gh-btn gh-btn-icon gh-btn-red\" {{action \"confirm\"}}>\n        <span>Regenerate your Staff Access Token</span>\n    </button>\n</div>\n","moduleName":"ghost-admin/components/modal-regenerate-token.hbs","parseOptions":{"srcName":"ghost-admin/components/modal-regenerate-token.hbs"}});
import ModalComponent from 'ghost-admin/components/modal-base';

export default ModalComponent.extend({
    actions: {
        confirm() {
            this.confirm();
            this.send('closeModal');
        }
    }
});
