import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<figure class=\"gh-member-gravatar {{@containerClass}}\">\n    <div class=\"gh-member-initials flex items-center justify-center br-100 {{@containerClass}}\" style={{this.backgroundStyle}}>\n        <span class=\"gh-member-avatar-label {{or @sizeClass \"gh-member-list-avatar\"}}\">{{this.initials}}</span>\n    </div>\n    <img class=\"gh-member-avatar-image\" src={{gravatar @member.email size=180}} />\n</figure>", {"contents":"<figure class=\"gh-member-gravatar {{@containerClass}}\">\n    <div class=\"gh-member-initials flex items-center justify-center br-100 {{@containerClass}}\" style={{this.backgroundStyle}}>\n        <span class=\"gh-member-avatar-label {{or @sizeClass \"gh-member-list-avatar\"}}\">{{this.initials}}</span>\n    </div>\n    <img class=\"gh-member-avatar-image\" src={{gravatar @member.email size=180}} />\n</figure>","moduleName":"ghost-admin/components/gh-member-avatar.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-member-avatar.hbs"}});
import Component from '@glimmer/component';
import {htmlSafe} from '@ember/template';

const stringToHslColor = function (str, saturation, lightness) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    var h = hash % 360;
    return 'hsl(' + h + ', ' + saturation + '%, ' + lightness + '%)';
};

export default class GhMemberAvatarComponent extends Component {
    get memberName() {
        let {member} = this.args;

        // can be a proxy in a sparse array so .get is required
        return member.get('name') || member.get('email') || 'NM';
    }

    get backgroundStyle() {
        let color = stringToHslColor(this.memberName, 75, 55);
        return htmlSafe(`background-color: ${color}`);
    }

    get initials() {
        if (this.memberName === 'NM') {
            return 'NM';
        }

        let names = this.memberName.split(' ');
        let intials = names.length > 1 ? [names[0][0], names[names.length - 1][0]] : [names[0][0]];
        return intials.join('').toUpperCase();
    }
}
