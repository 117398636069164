import ApplicationAdapter from 'ghost-admin/adapters/application';
import {inject as service} from '@ember/service';

import mutation from 'ghost-admin/gql/mutations/login';

export default class UserAdapter extends ApplicationAdapter {
    @service('apollo') apolloMainApi;

    queryRecord(store, type, query) {
        if (query.id === 'me') {
            return this.apolloMainApi.mutate({mutation})
                .then(res => ({
                    user: {
                        ...res.security.login.data,
                        id: res.security.login.data.login,
                        status: 'active',
                        email: res.security.login.data.login,
                        name: `${res.security.login.data.firstName} ${res.security.login.data.lastName}`
                    }
                }));
        }
    }
}
