import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span class=\"gh-select gh-import-member-select {{if @disabled \"disabled\"}} {{unless this.mapTo \"unmapped\"}}\">\n    <OneWaySelect @value={{this.mapTo}}\n        @options={{this.availableFields}}\n        @optionValuePath=\"value\"\n        @optionLabelPath=\"label\"\n        @optionTargetPath=\"value\"\n        @includeBlank={{true}}\n        @promptIsSelectable={{true}}\n        @prompt=\"Not imported\"\n        @update={{action \"updateMapping\"}}\n        @disabled={{@disabled}}\n    />\n    {{svg-jar \"arrow-down-small\"}}\n</span>", {"contents":"<span class=\"gh-select gh-import-member-select {{if @disabled \"disabled\"}} {{unless this.mapTo \"unmapped\"}}\">\n    <OneWaySelect @value={{this.mapTo}}\n        @options={{this.availableFields}}\n        @optionValuePath=\"value\"\n        @optionLabelPath=\"label\"\n        @optionTargetPath=\"value\"\n        @includeBlank={{true}}\n        @promptIsSelectable={{true}}\n        @prompt=\"Not imported\"\n        @update={{action \"updateMapping\"}}\n        @disabled={{@disabled}}\n    />\n    {{svg-jar \"arrow-down-small\"}}\n</span>","moduleName":"ghost-admin/components/gh-members-import-mapping-input.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-members-import-mapping-input.hbs"}});
import Component from '@glimmer/component';
import {action} from '@ember/object';
import {tracked} from '@glimmer/tracking';

const FIELD_MAPPINGS = [
    {label: 'Email', value: 'email'},
    {label: 'Name', value: 'name'},
    {label: 'Note', value: 'note'},
    {label: 'Subscribed to emails', value: 'subscribed_to_emails'},
    {label: 'Stripe Customer ID', value: 'stripe_customer_id'},
    {label: 'Complimentary plan', value: 'complimentary_plan'},
    {label: 'Labels', value: 'labels'},
    {label: 'Created at', value: 'created_at'}
];

export default class extends Component {
    @tracked availableFields = FIELD_MAPPINGS;

    get mapTo() {
        return this.args.mapTo;
    }

    @action
    updateMapping(newMapTo) {
        if (this.args.updateMapping) {
            this.args.updateMapping(this.args.mapFrom, newMapTo);
        }
    }
}
