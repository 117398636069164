import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#each this.messages as |message|}}\n    <GhNotification @message={{message}} />\n{{/each}}\n", {"contents":"{{#each this.messages as |message|}}\n    <GhNotification @message={{message}} />\n{{/each}}\n","moduleName":"ghost-admin/components/gh-notifications.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-notifications.hbs"}});
import Component from '@ember/component';
import {alias} from '@ember/object/computed';
import {inject as service} from '@ember/service';

export default Component.extend({
    notifications: service(),

    tagName: 'aside',
    classNames: 'gh-notifications',

    messages: alias('notifications.notifications')
});
