import AuthenticatedRoute from 'ghost-admin/routes/authenticated';

export default AuthenticatedRoute.extend({
    beforeModel(transition) {
        this._super(...arguments);

        // if the transition is not new->edit, reset the post on the controller
        // so that the editor view is cleared before showing the loading state
        if (transition.urlMethod !== 'replace') {
            let editor = this.controllerFor('editor');
            editor.set('post', null);
            editor.reset();
        }
    },

    model(params) {
        // eslint-disable-next-line camelcase
        const {post_id} = params;

        return this.store.queryRecord('post', {
            id: post_id
        });
    },

    serialize(model) {
        return {
            post_id: model.id
        };
    },

    // there's no specific controller for this route, instead all editor
    // handling is done on the editor route/controler
    setupController(controller, post) {
        let editor = this.controllerFor('editor');
        editor.setPost(post);
    }
});
