import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("    <div class=\"gh-branding-settings\">\n        <section class=\"gh-launch-wizard-settings-container\">\n            <GhBrandSettingsForm\n                class=\"overflow-y-auto flex-grow-1\"\n                @replacePreviewContents={{@replacePreviewContents}}\n            />\n\n            <div class=\"gh-launch-wizard-nav-buttons\">\n                <GhTaskButton\n                    @task={{this.saveAndContinueTask}}\n                    @buttonText={{html-safe (concat \"Save and continue \" (svg-jar \"arrow-right-tail\"))}}\n                    type=\"button\"\n                    class=\"gh-btn gh-btn-black gh-btn-icon-right gh-btn-large gh-launch-wizard-btn w-100\"\n                    data-test-button=\"wizard-next\"\n                />\n            </div>\n        </section>\n    </div>", {"contents":"    <div class=\"gh-branding-settings\">\n        <section class=\"gh-launch-wizard-settings-container\">\n            <GhBrandSettingsForm\n                class=\"overflow-y-auto flex-grow-1\"\n                @replacePreviewContents={{@replacePreviewContents}}\n            />\n\n            <div class=\"gh-launch-wizard-nav-buttons\">\n                <GhTaskButton\n                    @task={{this.saveAndContinueTask}}\n                    @buttonText={{html-safe (concat \"Save and continue \" (svg-jar \"arrow-right-tail\"))}}\n                    type=\"button\"\n                    class=\"gh-btn gh-btn-black gh-btn-icon-right gh-btn-large gh-launch-wizard-btn w-100\"\n                    data-test-button=\"wizard-next\"\n                />\n            </div>\n        </section>\n    </div>","moduleName":"ghost-admin/components/gh-launch-wizard/customise-design.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-launch-wizard/customise-design.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency-decorators';

export default class GhLaunchWizardCustomiseDesignComponent extends Component {
    @service notifications;
    @service settings;

    willDestroy() {
        super.willDestroy?.(...arguments);
        this.settings.rollbackAttributes();
        this.settings.errors.remove('accentColor');
    }

    @task
    *saveAndContinueTask() {
        try {
            if (this.settings.errors && this.settings.errors.length !== 0) {
                return;
            }
            yield this.settings.save();
            this.args.nextStep();
        } catch (error) {
            if (error) {
                this.notifications.showAPIError(error);
                throw error;
            }
        }
    }
}
