import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<header class=\"modal-header\">\n    <h1>Are you sure you want to delete this {{this.post.displayName}}?</h1>\n</header>\n<a class=\"close\" href=\"\" role=\"button\" title=\"Close\" {{action \"closeModal\"}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></a>\n\n<div class=\"modal-body\">\n    <p>\n        You're about to delete \"<strong>{{this.post.title}}</strong>\". This is permanent! We warned you, k?\n    </p>\n</div>\n\n<div class=\"modal-footer\">\n    <button {{action \"closeModal\"}} class=\"gh-btn\"><span>Cancel</span></button>\n    <GhTaskButton @buttonText=\"Delete\" @successText=\"Deleted\" @task={{this.deletePost}} @class=\"gh-btn gh-btn-red gh-btn-icon\" />\n</div>\n", {"contents":"<header class=\"modal-header\">\n    <h1>Are you sure you want to delete this {{this.post.displayName}}?</h1>\n</header>\n<a class=\"close\" href=\"\" role=\"button\" title=\"Close\" {{action \"closeModal\"}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></a>\n\n<div class=\"modal-body\">\n    <p>\n        You're about to delete \"<strong>{{this.post.title}}</strong>\". This is permanent! We warned you, k?\n    </p>\n</div>\n\n<div class=\"modal-footer\">\n    <button {{action \"closeModal\"}} class=\"gh-btn\"><span>Cancel</span></button>\n    <GhTaskButton @buttonText=\"Delete\" @successText=\"Deleted\" @task={{this.deletePost}} @class=\"gh-btn gh-btn-red gh-btn-icon\" />\n</div>\n","moduleName":"ghost-admin/components/modal-delete-post.hbs","parseOptions":{"srcName":"ghost-admin/components/modal-delete-post.hbs"}});
import ModalComponent from 'ghost-admin/components/modal-base';
import {alias} from '@ember/object/computed';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency';

export default ModalComponent.extend({
    notifications: service(),

    post: alias('model.post'),
    onSuccess: alias('model.onSuccess'),

    actions: {
        confirm() {
            this.deletePost.perform();
        }
    },

    _deletePost() {
        let post = this.post;

        return post.destroyRecord();
    },

    _success() {
        // clear any previous error messages
        this.notifications.closeAlerts('post.delete');

        // trigger the success action
        if (this.onSuccess) {
            this.onSuccess();
        }
    },

    _failure(error) {
        this.notifications.showAPIError(error, {key: 'post.delete.failed'});
    },

    deletePost: task(function* () {
        try {
            yield this._deletePost();
            this._success();
        } catch (e) {
            this._failure(e);
        } finally {
            this.send('closeModal');
        }
    }).drop()
});
