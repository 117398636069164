import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<header class=\"modal-header\" data-test-modal=\"delete-user\">\n    <h1>Upgrade to un-suspend this user</h1>\n</header>\n<button class=\"close\" title=\"Close\" {{on \"click\" this.closeModal}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></button>\n\n<div class=\"modal-body\">\n    <p>\n        {{html-safe this.model.message}} To give this user access to Ghost, upgrade to a different plan.\n    </p>\n</div>\n\n<div class=\"modal-footer\">\n    <button {{on \"click\" this.closeModal}} class=\"gh-btn\" data-test-button=\"cancel-upgrade\">\n        <span>Cancel</span>\n    </button>\n\n    <button {{on \"click\" (action \"upgrade\")}} class=\"gh-btn gh-btn-green\" data-test-button=\"upgrade-plan\">\n        <span>Upgrade my plan</span>\n    </button>\n</div>", {"contents":"<header class=\"modal-header\" data-test-modal=\"delete-user\">\n    <h1>Upgrade to un-suspend this user</h1>\n</header>\n<button class=\"close\" title=\"Close\" {{on \"click\" this.closeModal}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></button>\n\n<div class=\"modal-body\">\n    <p>\n        {{html-safe this.model.message}} To give this user access to Ghost, upgrade to a different plan.\n    </p>\n</div>\n\n<div class=\"modal-footer\">\n    <button {{on \"click\" this.closeModal}} class=\"gh-btn\" data-test-button=\"cancel-upgrade\">\n        <span>Cancel</span>\n    </button>\n\n    <button {{on \"click\" (action \"upgrade\")}} class=\"gh-btn gh-btn-green\" data-test-button=\"upgrade-plan\">\n        <span>Upgrade my plan</span>\n    </button>\n</div>","moduleName":"ghost-admin/components/modal-upgrade-unsuspend-user-host-limit.hbs","parseOptions":{"srcName":"ghost-admin/components/modal-upgrade-unsuspend-user-host-limit.hbs"}});
import ModalComponent from 'ghost-admin/components/modal-base';
import {inject as service} from '@ember/service';

export default ModalComponent.extend({
    router: service(),

    actions: {
        upgrade() {
            this.router.transitionTo('pro');
        },

        confirm() {
            this.send('upgrade');
        }
    }
});
