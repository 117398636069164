import AuthenticatedRoute from 'ghost-admin/routes/authenticated';
import {assign} from '@ember/polyfills';
import {isBlank} from '@ember/utils';
import {inject as service} from '@ember/service';

export default AuthenticatedRoute.extend({
    infinity: service(),
    router: service(),

    queryParams: {
        type: {refreshModel: true},
        access: {refreshModel: true},
        author: {refreshModel: true},
        tag: {refreshModel: true},
        order: {refreshModel: true}
    },

    modelName: 'post',

    perPage: 30,

    init() {
        this._super(...arguments);

        // if we're already on this route and we're transiting _to_ this route
        // then the filters are being changed and we shouldn't create a new
        // browser history entry
        // see https://github.com/TryGhost/Ghost/issues/11057
        this.router.on('routeWillChange', (transition) => {
            if (transition.to && (this.routeName === 'posts')) {
                let toThisRoute = transition.to.find(route => route.name === this.routeName);
                if (transition.from && transition.from.name === this.routeName && toThisRoute) {
                    transition.method('replace');
                }
            }
        });
    },

    model(params) {
        let queryParams = {};
        let paginationParams = {
            perPageParam: 'limit',
            totalPagesParam: 'meta.totalCount' //todo this does not seem to be taken into account
        };

        let filterParams = {tag: params.tag};

        assign(filterParams, this._getTypeFilters(params.type));

        if (params.author) {
            filterParams.author = params.author;
        }

        if (!isBlank(params.order)) {
            queryParams.order = params.order;
        }

        let filter = this._filterString(filterParams);
        if (!isBlank(filter)) {
            queryParams.filter = filter;
        }

        let perPage = this.perPage;
        let paginationSettings = assign({perPage, startingPage: 1}, paginationParams, queryParams);

        return this.infinity.model(this.modelName, paginationSettings);
    },

    actions: {
        queryParamsDidChange() {
            let contentList = document.querySelector('.content-list');
            if (contentList) {
                contentList.scrollTop = 0;
            }

            this._super(...arguments);
        }
    },

    buildRouteInfoMetadata() {
        return {
            titleToken: 'Posts'
        };
    },

    _getTypeFilters(type) {
        let status = '[draft,published]';

        switch (type) {
        case 'draft':
            status = 'draft';
            break;
        case 'published':
            status = 'published';
            break;
        }

        return {
            status
        };
    },

    _filterString(filter) {
        return Object.keys(filter).map((key) => {
            let value = filter[key];

            if (!isBlank(value)) {
                return `${key}:${filter[key]}`;
            }
        }).compact().join('+');
    }
});
