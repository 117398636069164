define("ghost-admin/components/koenig-card", ["exports", "koenig-editor/components/koenig-card"], function (_exports, _koenigCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _koenigCard.default;
    }
  });
});
