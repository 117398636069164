import Transform from '@ember-data/serializer/transform';

export default Transform.extend({
    deserialize(serialized, options) {
        if (serialized?.length === 0) {
            return options.defaultValue();
        }
        return serialized;
    },

    serialize(deserialized) {
        return deserialized;
    }
});
