import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"gh-progress-container\">\n    <div class=\"gh-progress-container-progress\">\n        <div\n            class=\"gh-progress-bar {{if this.isError \"-error\"}}\"\n            style={{this.progressStyle}}\n            data-test-progress-bar\n            data-test-progress-width={{this.percentage}}\n        ></div>\n    </div>\n</div>\n", {"contents":"<div class=\"gh-progress-container\">\n    <div class=\"gh-progress-container-progress\">\n        <div\n            class=\"gh-progress-bar {{if this.isError \"-error\"}}\"\n            style={{this.progressStyle}}\n            data-test-progress-bar\n            data-test-progress-width={{this.percentage}}\n        ></div>\n    </div>\n</div>\n","moduleName":"ghost-admin/components/gh-progress-bar.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-progress-bar.hbs"}});
import Component from '@ember/component';
import {htmlSafe} from '@ember/template';

export default Component.extend({
    tagName: '',

    // Public attributes
    percentage: 0,
    isError: false,

    // Internal attributes
    progressStyle: '',

    didReceiveAttrs() {
        this._super(...arguments);

        let percentage = this.percentage;
        let width = (percentage > 0) ? `${percentage}%` : '0';

        this.set('progressStyle', htmlSafe(`width: ${width}`));
    }

});
