import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#each this.messages as |message|}}\n    <GhAlert @message={{message}} />\n{{/each}}", {"contents":"{{#each this.messages as |message|}}\n    <GhAlert @message={{message}} />\n{{/each}}","moduleName":"ghost-admin/components/gh-alerts.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-alerts.hbs"}});
import Component from '@ember/component';
import {alias} from '@ember/object/computed';
import {inject as service} from '@ember/service';

export default Component.extend({
    notifications: service(),

    classNames: 'gh-alerts',
    tagName: 'aside',

    messages: alias('notifications.alerts')
});
