import ApplicationAdapter from 'ghost-admin/adapters/application';
import {get} from '@ember/object';

import createRecord from 'ghost-admin/gql/mutations/author/create';
import deleteRecord from 'ghost-admin/gql/mutations/author/delete';
import list from 'ghost-admin/gql/queries/author/list';
import publish from 'ghost-admin/gql/mutations/author/publish';
import unpublish from 'ghost-admin/gql/mutations/author/unpublish';
import updateRecord from 'ghost-admin/gql/mutations/author/update';

export default class AuthorAdapter extends ApplicationAdapter {
    query(store, type, query) {
        return this.apolloManageCMS.watchQuery({query: list, fetchPolicy: 'no-cache'}, `list`)
            .then(({data}) => {
                if (query?.include === 'count.posts') {
                    const authors = data.map((author) => {
                        return {
                            ...author,
                            count: {
                                posts: data.posts?.length || 0
                            }
                        };
                    });
                    return {
                        author: authors
                    };
                }
                return {author: data};
            });
    }

    queryRecord(store, type, query) {
        return this.apolloManageCMS.watchQuery({
            query: list,
            variables: {
                id: query.id
            },
            fetchPolicy: 'no-cache'
        }, 'list').then(({data}) => {
            return {author: data.length > 0 ? data[0] : {}};
        });
    }

    createRecord(store, type, snapshot) {
        const payload = this.preparePayload(store, type, snapshot);

        return this.apolloManageCMS.mutate({mutation: createRecord, variables: payload.data.author}, `createRecord`)
            .then(this.prepareResponse(type))
            .then(({author: {revisionId}}) => this.publish(revisionId))
            .then(this.prepareResponse(type));
    }

    updateRecord(store, type, snapshot) {
        const payload = this.preparePayload(store, type, snapshot);
        const {author} = payload.data;

        return this.apolloManageCMS.mutate({mutation: updateRecord, variables: {...author, id: author.revisionId}}, `updateRecord`)
            .then(this.prepareResponse(type))
            .then(({author: {revisionId}}) => this.publish(revisionId))
            .then(this.prepareResponse(type));
    }

    deleteRecord(store, type, snapshot) {
        const payload = this.preparePayload(store, type, snapshot);
        const variables = {
            id: get(payload, 'data.author.revisionId')
        };

        return this.unpublish(variables.id).then(
            () => {
                this.apolloManageCMS.mutate({mutation: deleteRecord, variables}, `deleteRecord`);
            }
        );
    }

    publish(id) {
        const variables = {
            id
        };
        return this.apolloManageCMS.mutate({mutation: publish, variables/*refetchQueries: ['GetAuthorList']*/}, 'publish');
    }

    unpublish(id) {
        const variables = {
            id
        };
        return this.apolloManageCMS.mutate({mutation: unpublish, variables/*, refetchQueries: ['GetAuthorList']*/}, 'unpublish');
    }
}
