import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span class=\"gh-select\" data-select-text=\"{{this.selectedTimezone.label}}\" tabindex=\"0\">\n    <OneWaySelect\n        @id=\"timezone\"\n        @name=\"general[timezone]\"\n        @options={{this.selectableTimezones}}\n        @optionValuePath=\"name\"\n        @optionLabelPath=\"label\"\n        @value={{this.selectedTimezone}}\n        @update={{action \"setTimezone\"}}\n    />\n    {{svg-jar \"arrow-down-small\"}}\n</span>\n{{#if this.hasTimezoneOverride}}\n    <p>Your timezone has been automatically set to {{this.timezone}}.</p>\n{{/if}}\n<p>The local time here is currently {{this.localTime}}</p>\n", {"contents":"<span class=\"gh-select\" data-select-text=\"{{this.selectedTimezone.label}}\" tabindex=\"0\">\n    <OneWaySelect\n        @id=\"timezone\"\n        @name=\"general[timezone]\"\n        @options={{this.selectableTimezones}}\n        @optionValuePath=\"name\"\n        @optionLabelPath=\"label\"\n        @value={{this.selectedTimezone}}\n        @update={{action \"setTimezone\"}}\n    />\n    {{svg-jar \"arrow-down-small\"}}\n</span>\n{{#if this.hasTimezoneOverride}}\n    <p>Your timezone has been automatically set to {{this.timezone}}.</p>\n{{/if}}\n<p>The local time here is currently {{this.localTime}}</p>\n","moduleName":"ghost-admin/components/gh-timezone-select.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-timezone-select.hbs"}});
import Component from '@ember/component';
import moment from 'moment';
import {computed} from '@ember/object';
import {mapBy} from '@ember/object/computed';
import {inject as service} from '@ember/service';

export default Component.extend({
    clock: service(),

    classNames: ['form-group', 'for-select'],

    timezone: null,
    availableTimezones: null,

    // Allowed actions
    update: () => {},

    availableTimezoneNames: mapBy('availableTimezones', 'name'),

    hasTimezoneOverride: computed('timezone', 'availableTimezoneNames', function () {
        let timezone = this.timezone;
        let availableTimezoneNames = this.availableTimezoneNames;

        return !availableTimezoneNames.includes(timezone);
    }),

    selectedTimezone: computed('timezone', 'availableTimezones', 'hasTimezoneOverride', function () {
        let hasTimezoneOverride = this.hasTimezoneOverride;
        let timezone = this.timezone;
        let availableTimezones = this.availableTimezones;

        if (hasTimezoneOverride) {
            return {name: '', label: ''};
        }

        return availableTimezones
            .filterBy('name', timezone)
            .get('firstObject');
    }),

    selectableTimezones: computed('availableTimezones', 'hasTimezoneOverride', function () {
        let hasTimezoneOverride = this.hasTimezoneOverride;
        let availableTimezones = this.availableTimezones;

        if (hasTimezoneOverride) {
            return [{name: '', label: ''}, ...availableTimezones];
        }

        return availableTimezones;
    }),

    localTime: computed('hasTimezoneOverride', 'timezone', 'selectedTimezone', 'clock.second', function () {
        let hasTimezoneOverride = this.hasTimezoneOverride;
        let timezone = hasTimezoneOverride ? this.timezone : this.get('selectedTimezone.name');

        this.get('clock.second');
        return timezone ? moment().tz(timezone).format('HH:mm:ss') : moment().utc().format('HH:mm:ss');
    }),

    actions: {
        setTimezone(timezone) {
            this.update(timezone);
        }
    }
});
