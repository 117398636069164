import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div {{did-insert (fn this.setSaveType \"publish\")}} ...attributes>\n    <header class=\"gh-publishmenu-heading\">Ready to {{this.nextActionName}} this post?</header>\n    <section class=\"gh-publishmenu-content\">\n        <div class=\"gh-publishmenu-section\">\n            <div class=\"gh-publishmenu-radio {{if (eq @saveType \"publish\") \"active\"}}\" {{on \"click\" (fn this.setSaveType \"publish\")}}>\n                <div class=\"gh-publishmenu-radio-button\" data-test-publishmenu-published-option></div>\n                <div class=\"gh-publishmenu-radio-content\">\n                    <div class=\"gh-publishmenu-radio-label\">Set it live now</div>\n                    <div class=\"gh-publishmenu-radio-desc\">Publish this post immediately</div>\n                </div>\n            </div>\n        </div>\n    </section>\n</div>", {"contents":"<div {{did-insert (fn this.setSaveType \"publish\")}} ...attributes>\n    <header class=\"gh-publishmenu-heading\">Ready to {{this.nextActionName}} this post?</header>\n    <section class=\"gh-publishmenu-content\">\n        <div class=\"gh-publishmenu-section\">\n            <div class=\"gh-publishmenu-radio {{if (eq @saveType \"publish\") \"active\"}}\" {{on \"click\" (fn this.setSaveType \"publish\")}}>\n                <div class=\"gh-publishmenu-radio-button\" data-test-publishmenu-published-option></div>\n                <div class=\"gh-publishmenu-radio-content\">\n                    <div class=\"gh-publishmenu-radio-label\">Set it live now</div>\n                    <div class=\"gh-publishmenu-radio-desc\">Publish this post immediately</div>\n                </div>\n            </div>\n        </div>\n    </section>\n</div>","moduleName":"ghost-admin/components/gh-publishmenu-draft.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-publishmenu-draft.hbs"}});
import Component from '@glimmer/component';
import moment from 'moment';
import {action} from '@ember/object';
import {isEmpty} from '@ember/utils';
import {inject as service} from '@ember/service';

export default class GhPublishMenuDraftComponent extends Component {
    @service config;
    @service feature;
    @service session;
    @service settings;
    @service store;

    // used to set minDate in datepicker
    _minDate = null;
    _publishedAtBlogTZ = null;

    get nextActionName() {
        return 'publish';
    }

    constructor() {
        super(...arguments);
        this.args.post.set('publishedAtBlogTZ', this.args.post.publishedAt);
    }

    @action
    setSaveType(type) {
        if (this.args.saveType !== type) {
            let hasDateError = !isEmpty(this.args.post.errors.errorsFor('publishedAtBlogDate'));
            let hasTimeError = !isEmpty(this.args.post.errors.errorsFor('publishedAtBlogTime'));
            let minDate = this._getMinDate();

            this._minDate = minDate;
            this.args.setSaveType(type);

            // when publish: switch to now to avoid validation errors
            // when schedule: switch to last valid or new minimum scheduled date
            if (type === 'publish') {
                if (!hasDateError && !hasTimeError) {
                    this._publishedAtBlogTZ = this.args.post.publishedAtBlogTZ;
                } else {
                    this._publishedAtBlogTZ = this.args.post.publishedAt;
                }

                this.args.post.set('publishedAtBlogTZ', this.args.post.publishedAt);
            } else {
                if (!this._publishedAtBlogTZ || moment(this._publishedAtBlogTZ).isBefore(minDate)) {
                    this.args.post.set('publishedAtBlogTZ', minDate);
                } else {
                    this.args.post.set('publishedAtBlogTZ', this._publishedAtBlogTZ);
                }
            }

            this.args.post.validate();
        }
    }

    @action
    setDate(date) {
        let post = this.args.post;
        let dateString = moment(date).format('YYYY-MM-DD');

        post.set('publishedAtBlogDate', dateString);
        return post.validate();
    }

    @action
    setTime(time) {
        let post = this.args.post;

        post.set('publishedAtBlogTime', time);
        return post.validate();
    }

    // API only accepts dates at least 2 mins in the future, default the
    // scheduled date 5 mins in the future to avoid immediate validation errors
    _getMinDate() {
        return moment.utc().add(5, 'minutes');
    }
}
