import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<header class=\"modal-header\">\n    <h1>Are you sure you want to delete this tag?</h1>\n</header>\n<a class=\"close\" href=\"\" role=\"button\" title=\"Close\" {{action \"closeModal\"}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></a>\n\n<div class=\"modal-body\">\n    {{#if this.tag.count.posts}}\n        <span class=\"red\">This tag is attached to {{this.tag.count.posts}} {{this.postInflection}}.</span>\n    {{/if}}\n    You're about to delete \"<strong>{{this.tag.name}}</strong>\". This is permanent! We warned you, k?\n</div>\n\n<div class=\"modal-footer\">\n    <button {{action \"closeModal\"}} class=\"gh-btn\"><span>Cancel</span></button>\n    <GhTaskButton @buttonText=\"Delete\" @successText=\"Deleted\" @task={{this.deleteTag}} @class=\"gh-btn gh-btn-red gh-btn-icon\" />\n</div>\n", {"contents":"<header class=\"modal-header\">\n    <h1>Are you sure you want to delete this tag?</h1>\n</header>\n<a class=\"close\" href=\"\" role=\"button\" title=\"Close\" {{action \"closeModal\"}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></a>\n\n<div class=\"modal-body\">\n    {{#if this.tag.count.posts}}\n        <span class=\"red\">This tag is attached to {{this.tag.count.posts}} {{this.postInflection}}.</span>\n    {{/if}}\n    You're about to delete \"<strong>{{this.tag.name}}</strong>\". This is permanent! We warned you, k?\n</div>\n\n<div class=\"modal-footer\">\n    <button {{action \"closeModal\"}} class=\"gh-btn\"><span>Cancel</span></button>\n    <GhTaskButton @buttonText=\"Delete\" @successText=\"Deleted\" @task={{this.deleteTag}} @class=\"gh-btn gh-btn-red gh-btn-icon\" />\n</div>\n","moduleName":"ghost-admin/components/modal-delete-tag.hbs","parseOptions":{"srcName":"ghost-admin/components/modal-delete-tag.hbs"}});
import ModalComponent from 'ghost-admin/components/modal-base';
import {alias} from '@ember/object/computed';
import {computed} from '@ember/object';
import {task} from 'ember-concurrency';

export default ModalComponent.extend({
    // Allowed actions
    confirm: () => {},

    tag: alias('model'),

    postInflection: computed('tag.count.posts', function () {
        return this.get('tag.count.posts') > 1 ? 'posts' : 'post';
    }),

    actions: {
        confirm() {
            this.deleteTag.perform();
        }
    },

    deleteTag: task(function* () {
        try {
            yield this.confirm();
        } finally {
            this.send('closeModal');
        }
    }).drop()
});
