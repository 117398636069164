import 'moment-timezone';
import Ember from 'ember';
import Model, {attr, belongsTo, hasMany} from '@ember-data/model';
import ValidationEngine from 'ghost-admin/mixins/validation-engine';
import boundOneWay from 'ghost-admin/utils/bound-one-way';
import moment from 'moment';

import {BLANK_DOC} from 'koenig-editor/components/koenig-editor';
import {computed, observer} from '@ember/object';
import {equal} from '@ember/object/computed';
import {isBlank} from '@ember/utils';
import {on} from '@ember/object/evented';
import {inject as service} from '@ember/service';

// ember-cli-shims doesn't export these so we must get them manually
const {Comparable} = Ember;

export default Model.extend(Comparable, ValidationEngine, {
    config: service(),
    feature: service(),
    ghostPaths: service(),
    clock: service(),
    settings: service(),

    validationType: 'post',

    revisionId: attr('string'),

    title: attr('string', {defaultValue: ''}),
    description: attr('string'),
    slug: attr('string'),
    publishedAt: attr('moment-utc'),
    metaDescription: attr('string'),
    metaTitle: attr('string'),
    img: attr('string'),
    status: attr('string', {defaultValue: 'draft'}),

    body: attr('editor-json', {defaultValue: () => JSON.parse(JSON.stringify(BLANK_DOC))}),

    tags: hasMany('tag'),
    author: belongsTo('author'),

    visibility: attr('string', {defaultValue: 'public'}),

    scratch: null,
    titleScratch: null,

    // HACK: used for validation so that date/time can be validated based on
    // eventual status rather than current status
    statusScratch: null,

    // For use by date/time pickers - will be validated then converted to UTC
    // on save. Updated by an observer whenever publishedAt changes.
    // Everything that revolves around publishedAt only cares about the saved
    // value so this should be almost entirely internal
    publishedAtBlogDate: '',
    publishedAtBlogTime: '',

    metaDescriptionScratch: boundOneWay('metaDescription'),
    metaTitleScratch: boundOneWay('metaTitle'),
    metaKeywordsScratch: boundOneWay('metaKeywords'),
    descriptionScratch: boundOneWay('description'),

    isPublished: equal('status', 'published'),
    isDraft: equal('status', 'draft'),

    isPublic: computed.equal('visibility', 'public'),

    visibilitySegment: computed('visibility', 'visibilityFilter', 'isPublic', function () {
        if (this.isPublic) {
            return this.settings.get('defaultContentVisibility') === 'paid' ? 'status:-free' : 'status:free,status:-free';
        } else {
            if (this.visibility === 'members') {
                return 'status:free,status:-free';
            }
            if (this.visibility === 'paid') {
                return 'status:-free';
            }
            if (this.visibility === 'filter') {
                return this.visibilityFilter;
            }
            return this.visibility;
        }
    }),

    publishedAtBlogTZ: computed('publishedAtBlogDate', 'publishedAtBlogTime', 'settings.timezone', {
        get() {
            return this._getPublishedAtBlogTZ();
        },
        set(key, value) {
            let momentValue = value ? moment(value) : null;
            this._setPublishedAtBlogStrings(momentValue);
            return this._getPublishedAtBlogTZ();
        }
    }),

    _getPublishedAtBlogTZ() {
        let publishedAt = this.publishedAt;
        let publishedAtBlogDate = this.publishedAtBlogDate;
        let publishedAtBlogTime = this.publishedAtBlogTime;
        let blogTimezone = this.get('settings.timezone');
        //todo should this ever be empty
        // console.log({publishedAt, publishedAtBlogDate, publishedAtBlogTime});

        if (!publishedAt && isBlank(publishedAtBlogDate) && isBlank(publishedAtBlogTime)) {
            return null;
        }

        if (publishedAtBlogDate && publishedAtBlogTime) {
            let publishedAtBlog = moment.tz(`${publishedAtBlogDate} ${publishedAtBlogTime}`, blogTimezone);

            /**
             * Note:
             * If you create a post and publish it, we send seconds to the database.
             * If you edit the post afterwards, ember would send the date without seconds, because
             * the `publishedAt` is based on `publishedAtBlogTime`, which is only in seconds.
             * The date time picker doesn't use seconds.
             *
             * This condition prevents the case:
             *   - you edit a post, but you don't change the published_at time
             *   - we keep the original date with seconds
             *
             * See https://github.com/TryGhost/Ghost/issues/8603#issuecomment-309538395.
             */
            if (publishedAt && publishedAtBlog.diff(publishedAt.clone().startOf('minutes')) === 0) {
                return publishedAt;
            }

            return publishedAtBlog;
        } else {
            return moment.tz(this.publishedAt, blogTimezone);
        }
    },

    // TODO: is there a better way to handle this?
    // eslint-disable-next-line ghost/ember/no-observers
    _setPublishedAtBlogTZ: on('init', observer('publishedAt', 'settings.timezone', function () {
        let publishedAt = this.publishedAt;
        this._setPublishedAtBlogStrings(publishedAt);
    })),

    _setPublishedAtBlogStrings(momentDate) {
        if (momentDate) {
            let blogTimezone = this.get('settings.timezone');
            let publishedAtBlog = moment.tz(momentDate, blogTimezone);

            this.set('publishedAtBlogDate', publishedAtBlog.format('YYYY-MM-DD'));
            this.set('publishedAtBlogTime', publishedAtBlog.format('HH:mm'));
        } else {
            this.set('publishedAtBlogDate', '');
            this.set('publishedAtBlogTime', '');
        }
    },

    // this is a hook added by the ValidationEngine mixin and is called after
    // successful validation and before this.save()
    //
    // the publishedAtBlog{Date/Time} strings are set separately so they can be
    // validated, grab that time if it exists and set the publishedAt
    beforeSave() {
        let publishedAtBlogTZ = this.publishedAtBlogTZ;
        let publishedAt = publishedAtBlogTZ ? publishedAtBlogTZ.utc() : null;
        this.set('publishedAt', publishedAt);
    }
});
