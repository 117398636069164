import AuthenticatedRoute from 'ghost-admin/routes/authenticated';
import {inject as service} from '@ember/service';

export default AuthenticatedRoute.extend({
    settings: service(),

    beforeModel() {
        this._super(...arguments);
    },

    setupController(controller) {
        // kick off the background fetch of integrations so that we can
        // show the screen immediately
        controller.fetchIntegrations.perform();
    },

    buildRouteInfoMetadata() {
        return {
            titleToken: 'Settings - Integrations'
        };
    }
});
