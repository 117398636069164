import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<button {{action \"openMobileMenu\" target=this.ui}} class=\"gh-mobilemenu-button\" role=\"presentation\">{{svg-jar \"icon\" class=\"icon-gh\"}}<span class=\"sr-only\">Menu</span></button>\n{{yield}}\n", {"contents":"<button {{action \"openMobileMenu\" target=this.ui}} class=\"gh-mobilemenu-button\" role=\"presentation\">{{svg-jar \"icon\" class=\"icon-gh\"}}<span class=\"sr-only\">Menu</span></button>\n{{yield}}\n","moduleName":"ghost-admin/components/gh-view-title.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-view-title.hbs"}});
import Component from '@ember/component';
import {inject as service} from '@ember/service';

export default Component.extend({
    ui: service(),

    tagName: 'h2',
    classNames: ['view-title']
});
