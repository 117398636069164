import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.showUpgradeButton}}\n    <button class=\"gh-btn gh-btn-green\" {{action \"openBilling\"}}><span>Upgrade</span></button>\n{{/if}}\n", {"contents":"{{#if this.showUpgradeButton}}\n    <button class=\"gh-btn gh-btn-green\" {{action \"openBilling\"}}><span>Upgrade</span></button>\n{{/if}}\n","moduleName":"ghost-admin/components/gh-billing-update-button.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-billing-update-button.hbs"}});
import Component from '@ember/component';
import {computed} from '@ember/object';
import {inject as service} from '@ember/service';

export default Component.extend({
    router: service(),
    config: service(),
    ghostPaths: service(),
    ajax: service(),
    billing: service(),

    subscription: null,

    showUpgradeButton: computed.reads('billing.subscription.isActiveTrial'),

    actions: {
        openBilling() {
            this.billing.openBillingWindow(this.router.currentURL, '/pro/billing/plans');
        }
    }
});
