import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{{this.config.blogUrl}}}", {"contents":"{{{this.config.blogUrl}}}","moduleName":"ghost-admin/components/gh-blog-url.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-blog-url.hbs"}});
import Component from '@ember/component';
import {inject as service} from '@ember/service';

export default Component.extend({
    config: service(),

    tagName: ''
});
