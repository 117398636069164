import AdapterError from '@ember-data/adapter/error';
import Mixin from '@ember/object/mixin';
import {assign} from '@ember/polyfills';
import {get, getProperties} from '@ember/object';
import {isBlank} from '@ember/utils';

const objectFilter = (object, fun) => {
    let result = {};
    Object.keys(object).forEach((key) => {
        let val = object[key];
        if (fun(val, key)) {
            result[key] = object[key];
        }
    });
    return result;
};

const objectReject = (object, fun) => {
    return objectFilter(
        object,
        (val, key) => !fun(val, key)
    );
};

export default Mixin.create({
    operationName(query) {
        try {
            return query.definitions[0].name.value;
        } catch (e) {
            return null;
        }
    },

    prepareQuery(query) {
        let preparedQuery = assign({}, query);
        preparedQuery.string = preparedQuery.string.replace(/}/g, `  __typename\n}`);
        return preparedQuery;
    },

    allowedVariables(query) {
        return query
            .definitions
            .map(
                def => (def.variableDefinitions || []).map(x => get(x, 'variable.name.value'))
            )
            .reduce((vars, acc) => acc.concat(vars), []);
    },

    normalizeVariables(variables, query) {
        if (!variables) {
            return {};
        }
        let allowedVariables = this.graphHelper('allowedVariables', query);

        return objectReject(
            getProperties(variables, allowedVariables),
            isBlank
        );
    },

    normalizeResponse(response) {
        if (response.errors) {
            throw new AdapterError(response.errors);
        } else {
            return this.get('store').serializerFor('application').normalize(response.data);
        }
    }
});
