import BaseValidator from './base';
import validator from 'validator';
import {isBlank, isPresent} from '@ember/utils';

export default BaseValidator.create({
    properties: [
        'title',
        'description',
        'metaTitle',
        'metaDescription',
        'metaKeywords',
        'publishedAtBlogTime',
        'publishedAtBlogDate',
        'author',
        'tags'
    ],

    title(model) {
        if (isBlank(model.title)) {
            model.errors.add('title', 'You must specify a title for the post.');
            this.invalidate();
        }

        if (!validator.isLength(model.title || '', 0, 255)) {
            model.errors.add('title', 'Title cannot be longer than 255 characters.');
            this.invalidate();
        }
    },

    author(model) {
        if (!(model.author)) {
            model.errors.add('author', 'At least one author is required.');
            this.invalidate();
        }
    },

    description(model) {
        if (!validator.isLength(model.description || '', 0, 300)) {
            model.errors.add('description', 'Excerpt cannot be longer than 300 characters.');
            this.invalidate();
        }
    },

    metaTitle(model) {
        if (!validator.isLength(model.metaTitle || '', 0, 300)) {
            model.errors.add('metaTitle', 'Meta Title cannot be longer than 300 characters.');
            this.invalidate();
        }
    },

    metaDescription(model) {
        if (!validator.isLength(model.metaDescription || '', 0, 500)) {
            model.errors.add('metaDescription', 'Meta Description cannot be longer than 500 characters.');
            this.invalidate();
        }
    },

    metaKeywords(model) {
        if (!validator.isLength(model.metaKeywords || '', 0, 500)) {
            //todo what meta keywords validation rules ?
            model.errors.add('metaKeywords', 'Meta Keywords cannot be longer than 500 characters.');
            this.invalidate();
        }
    },

    // for posts which haven't been published before and where the blog date/time
    // is blank we should ignore the validation
    _shouldValidatePublishedAtBlog(model) {
        return isPresent(model.publishedAt)
            || isPresent(model.publishedAtBlogDate)
            || isPresent(model.publishedAtBlogTime);
    },

    // convenience method as .validate({property: 'x'}) doesn't accept multiple properties
    publishedAtBlog(model) {
        this.publishedAtBlogTime(model);
        this.publishedAtBlogDate(model);
    },

    publishedAtBlogTime(model) {
        let timeRegex = /^(([0-1]?[0-9])|([2][0-3])):([0-5][0-9])$/;

        if (!timeRegex.test(model.publishedAtBlogTime) && this._shouldValidatePublishedAtBlog(model)) {
            model.errors.add('publishedAtBlogTime', 'Must be in format: "15:00"');
            this.invalidate();
        }
    },

    publishedAtBlogDate(model) {
        let publishedAtBlogDate = model.publishedAtBlogDate;
        let publishedAtBlogTime = model.publishedAtBlogTime;

        if (!this._shouldValidatePublishedAtBlog(model)) {
            return;
        }

        // we have a time string but no date string
        if (isBlank(publishedAtBlogDate) && !isBlank(publishedAtBlogTime)) {
            model.errors.add('publishedAtBlogDate', 'Can\'t be blank');
            return this.invalidate();
        }
    }
});
