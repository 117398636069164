import ESASessionService from 'ember-simple-auth/services/session';
import {inject as service} from '@ember/service';
import {tracked} from '@glimmer/tracking';

export default class SessionService extends ESASessionService {
    @service config;
    @service('store') dataStore;
    @service feature;
    @service notifications;
    @service router;
    @service settings;
    @service upgradeStatus;
    @service apollo;

    @tracked user = null;

    skipAuthSuccessHandler = false;

    async populateUser() {
        if (this.user) {
            return;
        }

        const id = 'me';
        this.user = await this.dataStore.queryRecord('user', {id});
    }

    postAuthPreparation() {
        this.config.fetchAuthenticated();
        this.feature.fetch();
        this.settings.fetch();
    }

    async handleAuthentication() {
        if (!this.user) {
            try {
                await this.populateUser();
            } catch (err) {
                await this.invalidate();
            }

            this.postAuthPreparation();
        }

        if (this.skipAuthSuccessHandler) {
            this.skipAuthSuccessHandler = false;
            return;
        }

        super.handleAuthentication('home');
    }
}
