define("ghost-admin/services/liquid-fire-transitions", ["exports", "liquid-wormhole/services/liquid-fire-transitions"], function (_exports, _liquidFireTransitions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _liquidFireTransitions.default;
    }
  });
});
