define("ghost-admin/services/ella-sparse", ["exports", "ember-ella-sparse/services/ella-sparse"], function (_exports, _ellaSparse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _ellaSparse.default;
    }
  });
});
