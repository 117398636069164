import Adapter from '@ember-data/adapter';
import {inject as service} from '@ember/service';

export default class ApplicationAdapter extends Adapter {
    @service('apollo-headless-cms') apolloManageCMS;

    preparePayload(store, type, snapshot) {
        const serializer = store.serializerFor(type.modelName);
        let payload = {};

        serializer.serializeIntoHash(payload, type, snapshot);
        return {data: payload};
    }

    prepareResponse(type) {
        return ({error, data}) => {
            if (error) {
                throw new Error(error.message);
            }
            const returnable = {};
            returnable[type.modelName] = data;
            return returnable;
        };
    }

    _filterFromString(filter) {
        let arr = {};
        filter.split('+').map((byPlus) => {
            const keyValuePair = byPlus.split(':');
            arr[keyValuePair[0]] = keyValuePair[1].replace(/[[\]]/g,'').split(',');
        });
        return arr;
    }
}
