import Controller from '@ember/controller';
import {alias, filter} from '@ember/object/computed';

export default Controller.extend({
    authors: alias('model'),

    filteredAuthors: filter('authors', function (author) {
        return !!author.id;
    })
});
