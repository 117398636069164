import ApplicationSerializer from 'ghost-admin/serializers/application';
import {isArray} from '@ember/array';

const removeNullValues = el => el !== null;
const peekRecord = store => (payload, modelType, id) => {
    //look in store for record
    const storeRecord = store.peekRecord(modelType, id);
    if (storeRecord) {
        const serialized = storeRecord.serialize();
        return {
            ...serialized,
            type: modelType,
            id
        };
    }

    //if not already in store try current payload
    if (!payload[modelType]) {
        return null;
    }

    const arr = payload[modelType]
        .filter(record => record.id === id);
    if (arr.length === 0) {
        return null;
    }

    return {
        ...arr[0],
        type: modelType
    };
};

export default class PostSerializer extends ApplicationSerializer {
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
        let posts = payload.post || payload.data;
        if (!isArray(posts)) {
            posts = [posts];
        }
        const peekStoreRecord = peekRecord(store);

        posts = posts.map((post) => {
            return {
                ...post,
                status: post.meta?.status ?? 'draft',
                tags: post.tags?.length ? post.tags.map(tag => peekStoreRecord(payload, tag.modelId, tag.entryId)).filter(removeNullValues) : [],
                author: post.author ? peekStoreRecord(payload, post.author.modelId, post.author.entryId) : null
            };
        });
        const newPayload = {
            ...payload,
            post: posts
        };
        return super.normalizeResponse(store, primaryModelClass, newPayload, id, requestType);
    }
}
