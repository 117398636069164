import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("\n<header class=\"modal-header\" data-test-modal=\"delete-user\">\n    <h1>{{headerMessage}}</h1>\n</header>\n<button class=\"close\" title=\"Close\" {{on \"click\" this.closeModal}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></button>\n\n<div class=\"modal-body\">\n    <p>\n        {{html-safe upgradeMessage.message}}\n    </p>\n</div>\n\n<div class=\"modal-footer\">\n    <button {{on \"click\" this.closeModal}} class=\"gh-btn\" data-test-button=\"cancel-upgrade\">\n        <span>Cancel</span>\n    </button>\n\n    <button {{on \"click\" (action \"upgrade\")}} class=\"gh-btn gh-btn-green\" data-test-button=\"upgrade-plan\">\n        <span>Upgrade</span>\n    </button>\n</div>", {"contents":"\n<header class=\"modal-header\" data-test-modal=\"delete-user\">\n    <h1>{{headerMessage}}</h1>\n</header>\n<button class=\"close\" title=\"Close\" {{on \"click\" this.closeModal}}>{{svg-jar \"close\"}}<span class=\"hidden\">Close</span></button>\n\n<div class=\"modal-body\">\n    <p>\n        {{html-safe upgradeMessage.message}}\n    </p>\n</div>\n\n<div class=\"modal-footer\">\n    <button {{on \"click\" this.closeModal}} class=\"gh-btn\" data-test-button=\"cancel-upgrade\">\n        <span>Cancel</span>\n    </button>\n\n    <button {{on \"click\" (action \"upgrade\")}} class=\"gh-btn gh-btn-green\" data-test-button=\"upgrade-plan\">\n        <span>Upgrade</span>\n    </button>\n</div>","moduleName":"ghost-admin/components/modal-upgrade-host-limit.hbs","parseOptions":{"srcName":"ghost-admin/components/modal-upgrade-host-limit.hbs"}});
import ModalComponent from 'ghost-admin/components/modal-base';
import {computed} from '@ember/object';
import {inject as service} from '@ember/service';

export default ModalComponent.extend({
    router: service(),

    headerMessage: computed('details', function () {
        let header = 'Upgrade to enable publishing';

        if (this.model.message && this.model.message.match(/account is currently in review/gi)) {
            header = `Hold up, we're missing some details`;
        }

        return header;
    }),

    upgradeMessage: computed('details', function () {
        const {limit, total} = this.model.details || {};
        const message = this.model.message;

        return {limit, total, message};
    }),

    actions: {
        upgrade() {
            this.router.transitionTo('pro');
        },

        confirm() {
            this.send('upgrade');
        }
    }
});
