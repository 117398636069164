import AuthenticatedRoute from 'ghost-admin/routes/authenticated';

export default AuthenticatedRoute.extend({
    model() {
        //todo author needs to be a select box
        return this.store.createRecord('post', {authors: [this.session.user]});
    },

    setupController(controller, newPost) {
        let editor = this.controllerFor('editor');
        editor.setPost(newPost);
    },

    buildRouteInfoMetadata() {
        return {
            mainClasses: ['editor-new']
        };
    }
});
