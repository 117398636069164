import Model, {attr} from '@ember-data/model';
import ValidationEngine from 'ghost-admin/mixins/validation-engine';
import {computed} from '@ember/object';

export default Model.extend(ValidationEngine, {
    validationType: 'author',
    firstName: attr('string'),
    lastName: attr('string'),
    entryId: attr('string'),
    revisionId: attr('string'),
    name: computed('firstName', 'lastName', function () {
        return `${this.firstName} ${this.lastName}`;
    })
});
