import AuthenticatedRoute from 'ghost-admin/routes/authenticated';
import {inject as service} from '@ember/service';

export default class MembersRoute extends AuthenticatedRoute {
    @service store;
    @service feature;

    queryParams = {
        label: {refreshModel: true},
        searchParam: {refreshModel: true, replace: true},
        paidParam: {refreshModel: true},
        orderParam: {refreshModel: true},
        filterParam: {refreshModel: true}
    };

    beforeModel() {
        super.beforeModel(...arguments);
    }

    model(params) {
        return this.controllerFor('members').fetchMembersTask.perform(params);
    }

    // trigger a background load of members plus labels for filter dropdown
    setupController(controller) {
        super.setupController(...arguments);
        controller.fetchLabelsTask.perform();
    }

    buildRouteInfoMetadata() {
        if (this.feature.membersFiltering) {
            return {
                titleToken: 'Members',
                mainClasses: ['gh-main-fullwidth']

            };
        } else {
            return {
                titleToken: 'Members'
            };
        }
    }

    resetController(controller, isExiting) {
        if (isExiting) {
            controller.reset();
        }
    }
}
