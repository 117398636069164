import Ember from 'ember';
import RSVP from 'rsvp';
import Service, {inject as service} from '@ember/service';
import timezoneData from '@tryghost/timezone-data';
import {computed} from '@ember/object';

// ember-cli-shims doesn't export _ProxyMixin
const {_ProxyMixin} = Ember;

export default Service.extend(_ProxyMixin, {
    session: service(),

    content: null,

    init() {
        this._super(...arguments);
        this.content = {};
    },

    fetch() {
        this.fetchUnauthenticated();

        if (this.session.isAuthenticated || this.session.data.authenticated) {
            this.fetchAuthenticated();
        }
    },

    fetchUnauthenticated() {
        const site = {
            blogTitle: 'Parsel CMS',
            description: 'Thoughts, stories and ideas.',
            logo: null,
            icon: null,
            accent_color: '#FF1A75',
            blogUrl: 'https://parsel.ai/blog/'.replace(/\/$/, ''),
            version: '4.12'
        };

        Object.assign(this.content, site);
        this.notifyPropertyChange('content');
    },

    fetchAuthenticated() {
        const config = {
            savedIndicator: true,
            snippetReplacements: true,
            enableDeveloperExperiments: true
        };
        const accessibility = {
            nightShift: true
        };
        const labs = {};

        Object.assign(this.content, config);
        Object.assign(this.content, accessibility);
        Object.assign(this.content, labs);
        this.notifyPropertyChange('content');
    },

    availableTimezones: computed(function () {
        return RSVP.resolve(timezoneData);
    }),

    blogDomain: computed('blogUrl', function () {
        let blogUrl = this.get('blogUrl');
        let blogDomain = blogUrl
            .replace(/^https?:\/\//, '')
            .replace(/\/?$/, '');

        return blogDomain;
    }),

    emailDomain: computed('blogDomain', function () {
        let blogDomain = this.blogDomain || '';
        const domainExp = blogDomain.match(new RegExp('^([^/:?#]+)(?:[/:?#]|$)', 'i'));
        const domain = (domainExp && domainExp[1]) || '';
        if (domain.startsWith('www.')) {
            return domain.replace(/^(www)\.(?=[^/]*\..{2,5})/, '');
        }
        return domain;
    }),

    getSiteUrl(path) {
        const siteUrl = new URL(this.get('blogUrl'));
        const subdir = siteUrl.pathname.endsWith('/') ? siteUrl.pathname : `${siteUrl.pathname}/`;
        const fullPath = `${subdir}${path.replace(/^\//, '')}`;

        return `${siteUrl.origin}${fullPath}`;
    }
});
