define("ghost-admin/utils/cognito-storage", ["exports", "ember-cognito/utils/cognito-storage"], function (_exports, _cognitoStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _cognitoStorage.default;
    }
  });
});
