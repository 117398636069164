import RESTSerializer from '@ember-data/serializer/rest';

const statusFilter = status => entity => status.includes(entity.meta.status);

export default class ApplicationSerializer extends RESTSerializer {
    serializeIntoHash(hash, type, record, options) {
        options = options || {};
        options.includeId = true;

        hash[type.modelName] = this.serialize(record, options);
    }

    normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
        const singular = primaryModelClass.modelName;
        const {filter = {status: ['published']}} = payload;

        const newPayload = {...payload};
        newPayload[singular] = payload[singular].filter(statusFilter(filter.status));
        return super.normalizeArrayResponse(store, primaryModelClass, newPayload, id, requestType);
    }
}
