import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div data-test-editor-post-status ...attributes>\n    {{#if this.isSaving}}\n        Saving...\n    {{else if (or @post.isPublished @post.pastScheduledTime)}}\n        Published\n        {{#if (or (eq @post.email.status \"submitting\") (eq @post.email.status \"submitting\"))}}\n            and sending to {{gh-pluralize @post.email.emailCount \"member\"}}\n        {{else if (eq @post.email.status \"submitted\")}}\n            and sent to {{gh-pluralize @post.email.emailCount \"member\"}}\n        {{/if}}\n        {{#if (feature \"savedIndicator\")}}\n            {{if (not @hasDirtyAttributes) \"- Saved\"}}\n        {{/if}}\n    {{else if @post.isNew}}\n        New\n    {{else}}\n        Draft\n        {{#if (feature \"savedIndicator\")}}\n            {{if (not @hasDirtyAttributes) \"- Saved\"}}\n        {{/if}}\n    {{/if}}\n</div>", {"contents":"<div data-test-editor-post-status ...attributes>\n    {{#if this.isSaving}}\n        Saving...\n    {{else if (or @post.isPublished @post.pastScheduledTime)}}\n        Published\n        {{#if (or (eq @post.email.status \"submitting\") (eq @post.email.status \"submitting\"))}}\n            and sending to {{gh-pluralize @post.email.emailCount \"member\"}}\n        {{else if (eq @post.email.status \"submitted\")}}\n            and sent to {{gh-pluralize @post.email.emailCount \"member\"}}\n        {{/if}}\n        {{#if (feature \"savedIndicator\")}}\n            {{if (not @hasDirtyAttributes) \"- Saved\"}}\n        {{/if}}\n    {{else if @post.isNew}}\n        New\n    {{else}}\n        Draft\n        {{#if (feature \"savedIndicator\")}}\n            {{if (not @hasDirtyAttributes) \"- Saved\"}}\n        {{/if}}\n    {{/if}}\n</div>","moduleName":"ghost-admin/components/gh-editor-post-status.hbs","parseOptions":{"srcName":"ghost-admin/components/gh-editor-post-status.hbs"}});
import Component from '@glimmer/component';
import config from 'ghost-admin/config/environment';
import {inject as service} from '@ember/service';
import {task} from 'ember-concurrency-decorators';
import {timeout} from 'ember-concurrency';
import {tracked} from '@glimmer/tracking';

export default class GhEditorPostStatusComponent extends Component {
    @service clock;
    @service settings;

    @tracked _isSaving = false;

    // this.args.isSaving will only be true briefly whilst the post is saving,
    // we want to ensure that the "Saving..." message is shown for at least
    // a few seconds so that it's noticeable so we use autotracking to trigger
    // a task that sets _isSaving to true for 3 seconds
    get isSaving() {
        if (this.args.isSaving) {
            this.showSavingMessage.perform();
        }

        return this._isSaving;
    }

    @task({drop: true})
    *showSavingMessage() {
        this._isSaving = true;
        yield timeout(config.environment === 'test' ? 0 : 3000);

        if (!this.isDestroyed && !this.isDestroying) {
            this._isSaving = false;
        }
    }
}
